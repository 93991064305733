import React, { useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import axios from 'axios';
import { URL } from '../../config';
import ReactGA from 'react-ga4';
import { getFolders, getBlogs } from '../apiHelpers';
import { folderState, UserState, blogState } from '../Recoil/atoms';
import { useRecoilState } from 'recoil';
import { Heart } from 'lucide-react';


const LikeButton = ({ userLikes, user, placeId, postId, folderId, setNewLikeCount, folderLike }) => {
    const [liked, setLiked] = useState(false);
    let likeCount = !!userLikes ? userLikes.length : 0;
    const [folders, setFolders] = useRecoilState(folderState);
    const [userInfo, setUserInfo] = useRecoilState(UserState);
    const [blogs, setBlogs] = useRecoilState(blogState);

    useEffect(() => {
        if (userLikes != null) {
            for (const like of userLikes) {
                if (like.user_id === user.id) {
                    setLiked(true);
                }
            }
        }
    },[userLikes, user])

    const handleUpdateLikes = async (e) => {
        ReactGA.event({
            category: 'Feed',
            action: 'User likes another users post'
        })
        e.preventDefault();
        setLiked(true);
        setNewLikeCount(likeCount => likeCount + 1);
        const userLike = {
            id: user.id,
            info: {
                username: user.username,
                firstName: user.first_name,
                lastName: user.last_name
            }
        }
        likeCount = likeCount += 1;
        const body = { 
            user: userLike,
            likeCount: likeCount
        }
        if (!!folderLike) {
            // const url = `${URL}/folders/${folderId}/user/${user.id}/like`;
            // const res = await axios.post(url, body);
            // getFolders(userInfo, setFolders);
            const url = `${URL}/blog/${folderId}/user/${user.id}/like`;
            const res = await axios.post(url);

        } else {
            // if (!postId) {
            //     postId = false;
            // } else if (typeof postId !== 'number') {
            //     postId = postId.id;
            // }
            // postId = postId || false;
            // console.log('post id: ', postId);
            // const url = `${URL}/feed/post/${postId}/item/${placeId}/folder/${folderId}/like`;
            // const res = await axios.post(url, body);
            // getFolders(userInfo, setFolders);
            const url = `${URL}/post/${postId}/user/${user.id}/like`;
            const res = await axios.post(url);
        }
    }

    const handleDeleteLike = async (e) => {
        e.preventDefault();
        ReactGA.event({
            category: 'Feed',
            action: 'User unlikes another users'
        })
        setLiked(false);
        setNewLikeCount(likeCount => likeCount - 1);
        if (!!folderLike) {
            // const url = `${URL}/folders/${folderId}/user/${user.id}/like`;
            // const res = await axios.delete(url);
            // return res;
            const url = `${URL}/blog/${folderId}/user/${user.id}/like`;
            const res = await axios.delete(url);
        } else {
            // const url = `${URL}/feed/post/${postId}/item/${placeId}/folder/${folderId}/user/${user.id}/like`;
            // const res = await axios.delete(url);
            const url = `${URL}/post/${postId}/user/${user.id}/like`;
            const res = await axios.delete(url);
        }
    }

    return (
        <>
        {!!liked ? (
            <Button size="sm" className='btn-transparent p-0 mb-0 mt-1' onClick={handleDeleteLike}>
                <Heart fill='red' color='red' size={27} />
                {/* <Image className='m-0 filter-danger' style={{ height: "1.8em" }} src={LikeFill} /> */}
            </Button>
        ) : (
            <Button size="sm" className='btn-transparent p-0 mb-0 mt-1' onClick={handleUpdateLikes}>
                {/* <Image className='m-0 filter-danger' style={{ height: "1.8em" }} src={LikeOutline} /> */}
                <Heart color='#424242' size={27} />
            </Button>
        )}
        </>
    )
}

export default LikeButton;