import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Image } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { URL, IMG_URL } from '../../config';
import { ChevronLeft, Plus } from 'lucide-react';
import UploadButton from '../Profile/UploadButton';
import { useNavigate } from 'react-router-dom';
import ReactGA from 'react-ga4';
import RenameBlogModal from './RenameBlogModal';
import { folderState, UserState, blogState } from '../Recoil/atoms';
import { useRecoilState } from 'recoil';
import TripOverview from '../Trips/TripOverview';
import { getFolders, getBlogs } from '../apiHelpers';
import FolderView from '../Trips/FolderView';
import Locations from './Locations';
import { useAuth0 } from '@auth0/auth0-react';


const BlogView = () => {
    const [blog, setBlog] = useState({});
    const [user, setUser] = useRecoilState(UserState);
    const [folders, setFolders] = useRecoilState(folderState);
    const [blogs, setBlogs] = useRecoilState(blogState);
    const { isAuthenticated } = useAuth0();

    const followView = user.id != blog.user_id;

    const { id } = useParams();

    const navigate = useNavigate();
    // console.log('blog: ', blog);

    const getBlog = async (id) => {
        const res = await axios.get(`${URL}/blog/${id}`);
        const blog = res.data;
        setBlog(blog);
    };

    useEffect(() => {
        if (!!id && isAuthenticated) {
            getBlog(id);
        }
    }, [id, blogs]);

    const getBlogBackground = () => {
        if (!!blog.background_image) {
            return `${IMG_URL}/${blog.background_image.path}`;
        } else {
            return "none";
        }
    };

    const uploadTripBackground = async (formData) => {
        ReactGA.event({
            category: "Blogs",
            action: "User adds background picture to blog"
        })
        const res = await axios.post(`${URL}/blog/${blog.id}/background`, formData, { headers: {'Content-Type': 'multipart/form-data'}});
        if (res.status === 200) {
            getBlog(id);
        }
    };


    const updateOverview = async (id, overview) => {
        ReactGA.event({
            category: "Blogs",
            action: "User Updates Blog"
        })
        const url = `${URL}/blog/${id}/overview`;
        const res = await axios.put(url, { overview });
        if (res.status === 201) {
            getBlog(id);
        }
    };


    const deleteItem = async (postId) => {
        ReactGA.event({
            category: "Blogs",
            action: "User deletes post"
        });

        const url = `${URL}/post/${postId}`;
        const res = await axios.delete(url);
        if (res.status === 204) {
            getBlog(id);
        }
    }


    const changeItemDay = async (postId, day) => {
        ReactGA.event({
            category: "Blogs",
            action: "User Updates Blog Item Day"
        });
        const url = `${URL}/post/${postId}/day/${day}`;
        const res = await axios.post(url);
        getBlog(id);
    };


    const updateItemNotes = async (postId, notes) => {
        ReactGA.event({
            category: "Blogs",
            action: "User updates post caption"
        });

        const url = `${URL}/post/${postId}/caption`;
        const res = await axios.post(url, { notes });
        getBlog(id);
    }


    const updateAffiliateLink = async (postId, link) => {
        ReactGA.event({
            category: "Blogs",
            action: "User updates post affiliate link"
        });

        const url = `${URL}/post/${postId}/affiliate_link`;
        const res = await axios.post(url, { link });
        console.log('res: ', res);
        getBlog(id);
    }

    const publishBlogPost = async (userId, username, folderId, folderName, item, profilePic, index) => {
        ReactGA.event({
            category: "Blogs",
            action: "User Publishes Blog Post to Feed"
        })

        const body = {
            place: item,
            folderName: folderName,
            username: username,
            profilePic: profilePic
        }
        const url = `${URL}/users/${userId}/folders/${folderId}/item/${index}/post`;
        const res = await axios.post(url, body);
        // console.log('Info being sent for publish: ', body);
        return res;
    };

    const updateBlogPost = async (id, place) => {
        ReactGA.event({
            category: "Blogs",
            action: "User Updates Blog Post to Feed"
        });

        const url = `${URL}/blogs/post/${id}`;
        const res = await axios.put(url, { place });
        return res;
    }

    return (
        <div className='main'>
            <Container>
            <Row>
                <Col className='text-left pl-0'>
                    <div className='d-inline cursor-pointer'onClick={() => navigate('/profile/share')}>
                        <ChevronLeft className='d-inline pb-1' color='#15084D' size={30} />
                        <p className='text-primary d-inline pb-0 mb-0' style={{ fontSize: "1.2rem" }}>Back</p>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col className='profile-background-img-container position-relative'>
                    {blog.background_image && <Image src={getBlogBackground()} className='w-100 full-center' />}
                    <div className='position-absolute p-3 text-left' style={{ top: "0", left: "0"}}>
                        <h2 className='text-white trip-heading'>{blog.name}</h2>
                    </div>
                    {!followView && (
                        <div className='position-absolute p-1 mr-1 mt-1 cursor-pointer' 
                                style={{ top: "0", right: "0", borderRadius: "50%", backgroundColor: "#424242" }}
                            >
                            <RenameBlogModal blog={blog} user={user} setBlogs={setBlogs} insideBlog={true} />
                        </div>
                    )}
                    {!followView && (
                        <div className='upload-camera-container'>
                            <UploadButton uploadRequest={uploadTripBackground} />
                        </div>
                    )}
                </Col>
            </Row>
            <Row>
                <Col className='p-0'>
                    <TripOverview updateOverview={updateOverview} folder={blog} currentFolder={blog} setFolders={setBlogs} followView={followView} />
                </Col>
            </Row>
            <Row>
                <Col>
                    <Locations ownProfile={blog.user_id === user.id} blog={blog} />
                </Col>
            </Row>
            {!followView && (
                <Row>
                    <Col>
                        <div className='cursor-pointer' onClick={() => navigate(`/post/guide/${id}/${blog.name}`)}>
                            <Plus size={64} color='#424242' />
                            <p>New Place</p>
                        </div>
                    </Col>
                </Row>
            )}
            <Row>
                <Col>
                    <FolderView 
                        blogView={user.id === blog.user_id} 
                        folder={blog} 
                        addAble={user.id !== blog.user_id} 
                        folders={folders} 
                        followView={followView} 
                        deleteItem={deleteItem} 
                        updateAffiliateLink={updateAffiliateLink}
                        updateItemNotes={updateItemNotes}
                        changeItemDay={changeItemDay}
                        ownProfile={user.id === blog.user_id}
                        publishBlogPost={publishBlogPost}
                        updateBlogPost={updateBlogPost}
                    />
                </Col>
            </Row>
        </Container>
        </div>
    );
};

export default BlogView;