import React, { useState } from 'react';
import { useRecoilState } from 'recoil';
import { Button, Modal, Form, Container, Alert, Row, Col, Image } from 'react-bootstrap';
import axios from 'axios';
import ReactGA from 'react-ga4';
import { URL } from '../../config';
import { getFolders } from '../apiHelpers';
import { folderState, UserState } from '../Recoil/atoms';
import Loading from '../../images/loading.gif';


const CreateBlogButton = ({ folderId }) => {
    const [showBlogModal, setShowBlogModal] = useState(false);
    const [newFolder, setNewFolder] = useState('');
    const [createFolderError, setCreateFolderError] = useState(false);
    const [folders, setFolders] = useRecoilState(folderState);
    const [user, setUser] = useRecoilState(UserState);
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);

    const toggleCreateBlogModal = () => setShowBlogModal(showBlogModal => !showBlogModal);

    const createBlog = async (folderId, userId, folderName, e) => {
        setError(false);
        setLoading(true);
        e.preventDefault();

        ReactGA.event({
            category: "Blogs",
            action: "User Creates New Blog From Trip"
        })

        if (newFolder.length < 1) {
            setCreateFolderError(true);
            return;
        } else {
            setCreateFolderError(false);
        }

        if (!!userId) {
            const res = await axios.post(`${URL}/users/${userId}/folders/${folderId}/transform/blog`, {folderName});
            if (res.status === 200) {
                setLoading(false);
                setSuccess(true);
                setTimeout(() => setShowBlogModal(false), 1500);
                setTimeout(() => setSuccess(false), 1500);
                getFolders(user, setFolders);
            } else {
                setError(true);
            }
        }
    }



    return (
        <Container className="mt-0 mr-0">
            <Button size="md" className='mt-1 btn-secondary' onClick={toggleCreateBlogModal}>Create Album</Button>
            <Modal show={showBlogModal} onHide={toggleCreateBlogModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Create A New Trip Album</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={(e) => createBlog(folderId, user.id, newFolder, e)}>
                        <Form.Control size="md" className="bg-light border-0" placeholder="Name a Past Trip!" value={newFolder} onChange={e => setNewFolder(e.target.value)} />
                        <Modal.Footer>
                            <Row className='m-0 p-0'>
                                <Col className='col-md-2 offset-md-5'>
                                    {loading && (
                                        <div className='w-100'>
                                            <Image src={Loading} className='mw-100' alt='loading...' />
                                        </div>
                                    )}
                                </Col>
                            </Row>
                            <Row className='m-0 p-0'>
                                {error && <h6 className="text-danger">Error creating Album. Please try again later.</h6>}
                                {success && <Alert variant='success' className='p-1 mt-2 text-center mr-2'>Album Successfully Created!</Alert>}
                            </Row>
                            <Row className='m-0- p-0'>
                                <Button variant="primary" type="submit">
                                    Create
                                </Button>
                                <Button variant="secondary ml-1" onClick={toggleCreateBlogModal}>
                                    Close
                                </Button>
                            </Row>
                    </Modal.Footer>
                    </Form>
                </Modal.Body>
            </Modal>
        </Container>
    )
}

export default CreateBlogButton;