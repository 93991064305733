import React, { useEffect, useState } from 'react';
import { Row, Col, Form, Container } from 'react-bootstrap';
import moment from 'moment';
import 'react-dates/initialize';
import { DateRangePicker } from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';
import axios from 'axios';
import { URL } from '../../config';
import { getFolders } from '../apiHelpers';
import { folderState, UserState } from '../Recoil/atoms';
import { useRecoilState } from 'recoil';
import ReactGA from 'react-ga4';

const TripDateRange = ({ folder }) => {
    const [startDate, setStartDate] = useState(!!folder.start_date ? moment(new Date(folder.start_date)) : null);
    const [endDate, setEndDate] = useState(!!folder.end_date ? moment(new Date(folder.end_date)) : null);
    const [focusedInput, setFocusedInput] = useState(null);
    const [folders, setFolders] = useRecoilState(folderState);
    const [userInfo, getUserInfo] = useRecoilState(UserState);

    const dateToString = (date) => {
        return moment(new Date(date)).format('L').toString();
    };

    const handleDateChange = ({startDate, endDate}) => {
        setStartDate(startDate);
        setEndDate(endDate)
    };

    const handleFocusChange = (focusedInput) => {
        setFocusedInput(focusedInput);
    }

    const postDates = async (folder, startDate, endDate) => {
        ReactGA.event({
            category: "Trips",
            action: "User Added Dates To Trip"
        })
        const res = await axios.post(`${URL}/users/folders/${folder.id}/tripdates`, { startDate: startDate, endDate: endDate})
        if (res.status === 200) {
            // console.log('post dates response: ', res)
            getFolders(userInfo, setFolders);
        }
    }

    useEffect(() => {
            const startDateStr = dateToString(startDate);
            const endDateStr = dateToString(endDate);
            if (startDateStr != '12/31/1969' && endDateStr != '12/31/1969' && (endDateStr !== folder.end_date || startDateStr !== folder.start_date)) {
               postDates(folder, startDateStr, endDateStr);
            }

    }, [endDate])

    return (
            <Container className="mt-0 pt-0 ml-0 mb-1">
                    <DateRangePicker 
                        startDate={startDate}
                        startDateId={`folder_${folder.id}_start_date`}
                        endDate={endDate}
                        endDateId={`folder_${folder.id}_end_date`}
                        onDatesChange={handleDateChange}
                        focusedInput={focusedInput}
                        onFocusChange={handleFocusChange}
                        verticalHeight={370}
                        orientation='vertical'
                        numberOfMonths={1}
                        
                    />
            </Container>
    )
}

export default TripDateRange;