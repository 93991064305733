import React, { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import Discover from './Discover';
import { MapPinPlus } from 'lucide-react';


const SearchModal = ({ setPlace, setPlaces, places, postView, tripView, folder, blogView, iconSize }) => {
    const [showModal, setShowModal] = useState(false);

    const toggleModal = () => setShowModal(showModal => !showModal);
    
    const setPostPlace = (place) => {
        if (!!places) {
            let newPlaces = [...places];
            // console.log('place: ', place);
            newPlaces.push(place);
            setPlaces(newPlaces);
            setShowModal(false);
        } else {
            setPlace(place);
            setShowModal(false);
        }
    }

    return (
        <>
            <div>
                <Button className='btn-transparent p-0' onClick={toggleModal}><MapPinPlus size={iconSize} color='#424242' /></Button>
            </div>
            <Modal dialogClassName='wide-modal' show={showModal} onHide={toggleModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Search Locations</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Discover setPostPlace={setPostPlace} postView={postView} tripView={tripView} folder={folder} blogView={blogView} />
                </Modal.Body>
                <Modal.Footer className='bg-light'>
                    <Button variant="secondary" onClick={toggleModal}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default SearchModal;