import React, { useEffect, useState } from 'react';
import { Form, Col, Row, Button, Image } from 'react-bootstrap';
import EditIcon from '../../images/edit-icon.svg';
import { folderState, UserState, blogState } from '../Recoil/atoms';
import { useRecoilState } from 'recoil';
import { getFolders, getBlogs } from '../apiHelpers';
import { Pencil } from 'lucide-react';


const TripOverview = ({ tripView, memoryView, followView, updateOverview, folder, currentFolder }) => {
    const [overview, setOverview] = useState(!!currentFolder && currentFolder.overview ? currentFolder.overview : '');
    const [overViewEditable, setOverviewEditable] = useState(false);
    const [user, setUser] = useRecoilState(UserState);
    const [folders, setFolders] = useRecoilState(folderState);
    const [blogs, setBlogs] = useRecoilState(blogState)

    const handleOverviewSubmit = (e) => {
        e.preventDefault();
        setOverviewEditable(false);
        updateOverview(folder.id, overview);
    }

    useEffect(() => {
        if (overview != currentFolder.overview && currentFolder.overview != '') {
            setOverview(currentFolder.overview);
        }
    }, [currentFolder])

    return (
        <div className='m-0 p-0'>
        {!!overViewEditable && !followView ? (
            <Row className='m-0 p-0 mw-100 w-100'>
                <Col>
                    <Form onSubmit={handleOverviewSubmit} className='ml-3 mr-3 border-top'>
                        <Form.Group as={Row} className='mt-3 mb-1 pb-0 text-left'>
                            <Form.Label className='mb-0'><h4 className='mb-0'>Trip Overview:</h4></Form.Label>
                        </Form.Group>
                        <Form.Group as={Row} className='mb-1'>
                            <Form.Control as="textarea" rows={2} placeholder='Add an overview to your trip.' value={overview} onChange={e => setOverview(e.target.value)} id='overviewInput' />
                        </Form.Group>
                        <Form.Group as={Row} className='mb-1 pr-0 border-bottom'>
                            <Col className='right-align d-inline'>
                                {/* <Button className='btn-primary p-1 d-inline' type="submit" size="sm"><Image className='m-0 filter-white' style={{ height: "1.8em" }} src={SaveIcon} /></Button> */}
                                <Button className='btn-secondary pr-2 pl-2 pt-1 pb-1 d-inline ml-2 mb-1' onClick={() => setOverviewEditable(false)} size="md">Cancel</Button>
                                <Button className='btn-primary pr-2 pl-2 pt-1 pb-1 d-inline ml-2 mb-1' type="submit" size="md">Save</Button>
                            </Col>
                        </Form.Group>
                    </Form>
                </Col>
            </Row>
            )
            : ((!!followView && folder.overview) || !followView) ? (
            <Row className='m-0 p-0 mw-100 w-100'>
                <Col className='m-0 p-0'>
                    <Row className='border-top m-0'>
                        <Col className={`mt-3 text-left ${!!followView ? 'col-md-12' : 'col-md-9'}`}>
                            <h4 className='m-0'>Trip Overview:</h4>
                        </Col>
                        {!followView && (
                            <Col className='mt-1 col-md-3 right-align'>
                                <Button size="sm" className='btn-transparent' onClick={() => setOverviewEditable(true)}><Pencil color='#424242' size={20} /></Button>
                            </Col>
                        )}
                    </Row>
                    <Row className='text-left border-bottom m-0'>
                        <Col className='mw-100'>
                            <p className='text-dark mb-2 mw-100'>{!!folder.overview ? folder.overview : 'Add an overview to your trip.'}</p>
                        </Col>
                    </Row>
                </Col>
            </Row>
            ) : null
        }
        </div>
    )
}

export default TripOverview;
