import React, { useState } from "react";
import { Button, Modal, InputGroup, Form, Row, Col, Container, Alert } from 'react-bootstrap';
import ReactGA from 'react-ga4';

const InviteUserModal = ({ inviteNewUser, folderId}) => {
    const [showModal, setShowModal] = useState(false);
    const [newUser, setNewUser] = useState('');
    const [inviteError, setInviteError] = useState(false);
    const [inviteSuccess, setInviteSuccess] = useState(false);

    const handleClose = () => setShowModal(false);
    const handleShow = () => setShowModal(true);
    const inviteUser = async (e) => {
        // console.log('adding user: ', newUser)
        // console.log('folder Id: ', folderId);
        setInviteError(false);
        e.preventDefault();
        const res = await inviteNewUser(newUser, folderId)
        if (res === 201) {
            setInviteSuccess(true);
            setTimeout(() => setShowModal(false), 1500);
            setTimeout(() => setInviteSuccess(false), 1500);
        } else {
            setInviteError(true)
        }
        setNewUser('');
        ReactGA.event({
            category: "Social",
            action: "User Invites User to Trip"
        })
    }

    return (
        <Container className="mt-0 mr-0">
            <Button variant="primary" size="md" className="mt-1" onClick={handleShow}>
                Invite Friend!
            </Button>
            <Modal show={showModal} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Invite A Friend To Your Trip</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h6 className="mb-3 text-dark">Inviting a friend allows them to view the contents of your trip, add places, and edit times.</h6>
                    <Form onSubmit={inviteUser}>
                        <InputGroup>
                            <InputGroup.Text>@</InputGroup.Text>
                            <Form.Control size="md" className="bg-light border-0" placeholder="username" value={newUser} onChange={e => setNewUser(e.target.value)} />
                            {inviteError && <h6 className="text-danger">Error inviting user. Please check spelling and capitalization.</h6>}
                        </InputGroup>
                        {inviteSuccess && <Alert variant='success' className='p-1 mt-2 text-center'>User successfully Invited!</Alert>}
                        <Modal.Footer>
                        <Button variant="primary" type="submit">
                            Invite Friend!
                        </Button>
                        <Button variant="secondary" onClick={handleClose}>
                            Close
                        </Button>
                    </Modal.Footer>
                    </Form>
                </Modal.Body>
            </Modal>
        </Container>
    )
}

export default InviteUserModal;