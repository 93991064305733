import React, { useState, useRef } from 'react';
import { Button } from 'react-bootstrap';
import { UserState, folderState, blogState } from '../Recoil/atoms';
import { useRecoilState } from 'recoil';
import { URL } from '../../config';
import axios from 'axios';
import { getFolders, getBlogs } from '../apiHelpers';
import ReactGA from 'react-ga4';
import { Plus, Minus } from 'lucide-react';

const AddPhotoButton = ({ folderId, index, item, photoIndex, setPhotoIndex, setLoading }) => {
    const [user, setUser] = useRecoilState(UserState);
    const [folders, setFolders] = useRecoilState(folderState);
    const [uploadedFileName, setUploadedFileName] = useState(null);
    const inputRef = useRef(null);
    const [blogs, setBlogs] = useRecoilState(blogState);

    const handleUpload = async () => {
        inputRef.current?.click();
    };

    const handleDisplayFileDetails = async () => {
        inputRef.current?.files &&
            setUploadedFileName(inputRef.current.files[0].name);
    };


    const submitUpload = async (e) => {
        // e.preventDefault();
        if (inputRef.current?.files) {
            setLoading(true);
            ReactGA.event({
                category: 'Albums',
                action: 'User adds photos to blog post'
            })
            // setUploadedFileName(null);
            const formData = new FormData();
            const files = inputRef.current.files;
            for (let i = 0; i < files.length; i++) {
                formData.append('files', files[i])
            }
            // const res = await axios.post(`${URL}/users/folders/${folderId}/item/${index}/photos`, formData, { headers: {'Content-Type': 'multipart/form-data'}});
            try {
                const res = await axios.post(`${URL}/post/${item.id}/photos`, formData, { headers: {'Content-Type': 'multipart/form-data'}});
                // console.log(res);
                if (res.status === 201) {
                    
                    await getBlogs(user, setBlogs);
                }
                setLoading(false);
            } catch (err) {
                setLoading(false);
            }
        }
    }

    const deletePhoto = async (e) => {
        e.preventDefault();
        ReactGA.event({
            category: 'Blogs',
            action: 'User deletes photo from blog post'
        })
        const photoId = item.user_photos[photoIndex].id;
        // const url = `${URL}/users/folders/${folderId}/item/${index}/photos/${photoIndex}`;
        const url = `${URL}/post/photo/${photoId}`;
        const res = await axios.delete(url);
        if (res.status === 204) {
            setPhotoIndex(0);
            getBlogs(user, setBlogs);
        }
    }  

    return (
        <div className='m-0'>
            <form onSubmit={submitUpload}>
            <input
                ref={inputRef}
                onChange={submitUpload}
                className='d-none'
                type='file'
                name='file'
                accept='video/*,image/*,.heic'
                multiple
            />
            <Button
                onClick={handleUpload}
                size="sm"
                className='btn-transparent'
            >
                <Plus color='#424242' size={30} />
            </Button>
            {uploadedFileName && <Button size='sm' className='ml-2' type='submit'>Upload</Button>}
            {!!item.user_photos && item.user_photos.length > 0 && <Button size='sm' className='btn-transparent ml-2' onClick={deletePhoto} ><Minus color='#424242' size={30} /></Button>}
            </form>
            <p className='geo-text m-0 p-0'>Add Photo or Video</p>
        </div>
    )
};

export default AddPhotoButton;