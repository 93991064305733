import React, { useState, useEffect, Suspense } from 'react';
import {
    Route,
    Routes,
    useNavigate,
    useLocation
} from 'react-router-dom';
import Trips from './components/Trips/Trips.js';
import Follow from './components/FollowView/Follow.js';
const UserAccount = React.lazy(() => import('./components/AccountView/UserAccount.js'));
import  { withCookies } from 'react-cookie';
import { useRecoilState } from 'recoil';
import { folderState, UserState, newsFeedState, blogState } from './components/Recoil/atoms';
import { getFolders } from './components/apiHelpers';
import { useAuth0 } from '@auth0/auth0-react';
import NavBar from './components/NavBar';
import { getUserInfo, getBlogs } from './components/apiHelpers';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import HomePage from './components/HomePage/HomePage.js';
import ReactGA from 'react-ga4';
const PrivacyPolicy = React.lazy(() => import('./components/PrivacyPolicy/PrivacyPolicy.js'));
const TermsOfService = React.lazy(() => import('./components/TermsOfService/TermsOfService.js'));
import UserView from './components/FollowView/UserView.js';
const Notifications = React.lazy(() => import('./components/Notifications/Notifications.js'));
import MobileHeader from './components/MobileHeader.js';
import SignUpForm from './components/HomePage/SignUpForm.js';
import Search from './components/DiscoverView/Search.js';
import NewBlog from './components/NewBlog/NewBlog.js';
import NewPost from './components/NewPost/NewPost.js';
import Feed from './components/Feed/Feed.js';
import Loading from './components/Loading.js';
import BlogView from './components/Blogs/BlogView.js';
import { URL } from './config.js';
import axios from 'axios';
const LazyTrips = React.lazy(() => import('./components/Trips/Trips.js'));


const protectedRoute = (Component) => {
    return withAuthenticationRequired(Component, {
        onRedirecting: () => <Loading />,
    })
};

const ProtectedFeed = protectedRoute(Feed);
const ProtectedTrips = protectedRoute(Trips);
const ProtectedFollow = protectedRoute(Follow);
const ProtectedUserAccount = protectedRoute(UserAccount);
const ProtectedUserView = protectedRoute(UserView);
const ProtectedNotifications = protectedRoute(Notifications);
const ProtectedSignUpForm = protectedRoute(SignUpForm);
const ProtectedSearch = protectedRoute(Search);
const ProtectedNewBlog = protectedRoute(NewBlog);
const ProtectedBlogView = protectedRoute(BlogView);
const ProtectedNewPost = protectedRoute(NewPost);
const ProtectedLazyTrips = protectedRoute(LazyTrips);


const App = () => {
    const [folders, setFolders] = useRecoilState(folderState);
    const [userInfo, setUserInfo] = useRecoilState(UserState);
    const [newsFeedData, setNewsFeedData] = useRecoilState(newsFeedState);
    const { isAuthenticated, user, loginWithRedirect } = useAuth0();
    const [notifications, setNotifications] = useState([]);
    const navigate = useNavigate();
    const location = useLocation();
    const [prevPath, setPrevPath] = useState('/');
    const [blogs, setBlogs] = useRecoilState(blogState);
    const [notificationCount, setNotificationCount] = useState(0);

    const getNotifications = async (user) => {
        const url = `${URL}/notifications/user/${user.id}`;
        const res = await axios.get(url, { params: {lastCheck: user.notification_check }});
        setNotifications(res.data);
        setNotificationCount(res.data.new.length);
    };

    const getUserData = async () => {
        const userInfo = await getUserInfo(user.email, setUserInfo);
        // console.log('user info: ', userInfo);
        if (!!userInfo && userInfo.id) {
            getFolders(userInfo, setFolders);
            getBlogs(userInfo, setBlogs);
            getNotifications(userInfo);
        }
        // if (userInfo && !!userInfo.pending_followers) {
        //     // const pendingFollowers = Object.keys(userInfo.pending_followers).length;
        //     const newNotifications = await getNotifications()
        //     setNotifications(newNotifications);
        // }
    };

    useEffect(() => {
        if (isAuthenticated && !!user) {
            getUserData();
            // navigate('/feed');
        }
    }, [user]);

    // useEffect(() => {
    //     if (userInfo.id) {
    //         getNotifications();
    //     }
    // }, [userInfo]);


    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: location.pathname})
        if (prevPath === '/notifications') {
            getUserData();
        }
        setPrevPath(location.pathname);
    }, [location])

    useEffect(() => {
        if (!userInfo && !!user) {
            navigate('/signup');
        } else if (!!userInfo && location.pathname === '/signup') {
            navigate('/feed');
        }
    }, [userInfo])

    return (
            <div id="app" className="main">
            {isAuthenticated && userInfo && <NavBar notifications={notificationCount} /> }
            {isAuthenticated && userInfo && <MobileHeader />}
            <div className='mobile-padding'>
            <Suspense fallback={<Loading />}>
                <Routes>
                    <Route path="/signup" element={<ProtectedSignUpForm />} />
                    <Route path="/" element={<HomePage />} />
                    <Route path="/feed" element={<ProtectedFeed />} />
                    <Route path="/profile/plan" element={<ProtectedLazyTrips />} />
                    <Route path="/profile/share" element={<ProtectedLazyTrips />} />
                    <Route path="/profile/dream" element={<ProtectedTrips />} />
                    <Route path='/search' element={<ProtectedFollow />} />
                    <Route path="/settings" element={<ProtectedUserAccount />} />
                    <Route path="/privacy" element={<PrivacyPolicy />} />
                    <Route path="/terms" element={<TermsOfService />} />
                    <Route path="/:username" element={<ProtectedUserView />} />
                    <Route path="/notifications" element={<ProtectedNotifications setNotificationCount={setNotificationCount} notifications={notifications} />} />
                    <Route path='/post' element={<ProtectedNewBlog />} />
                    <Route path='/guide/:id' element={<ProtectedBlogView />} />
                    <Route path='/post/guide/:blogid/:name' element={<ProtectedNewPost />} />
                </Routes>
            </Suspense>
            </div>
            {/* <Footer /> */}
            </div>

    );
};

export default App;