import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Image, Modal, Button } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { URL, IMG_URL } from '../../config';
import { ChevronLeft, Plus } from 'lucide-react';
import UploadButton from '../Profile/UploadButton';
import { useNavigate } from 'react-router-dom';
import ReactGA from 'react-ga4';
import RenameBlogModal from './RenameBlogModal';
import { folderState, UserState, blogState } from '../Recoil/atoms';
import { useRecoilState } from 'recoil';
import TripOverview from '../Trips/TripOverview';
import { getFolders, getBlogs } from '../apiHelpers';
import FolderView from '../Trips/FolderView';
import Locations from './Locations';
import { X } from 'lucide-react';



const BlogModal = ({ showModal, toggleModal, id }) => {
    const [blog, setBlog] = useState({});
    const [user, setUser] = useRecoilState(UserState);
    const [folders, setFolders] = useRecoilState(folderState);
    const [blogs, setBlogs] = useRecoilState(blogState);

    const getBlog = async (id) => {
        const res = await axios.get(`${URL}/blog/${id}`);
        const blog = res.data;
        setBlog(blog);
    };

    useEffect(() => {
        if (!!id && !!showModal) {
            getBlog(id);
        }
    }, [id, showModal]);

    const getBlogBackground = () => {
        if (!!blog.background_image) {
            return `${IMG_URL}/${blog.background_image.path}`;
        } else {
            return "none";
        }
    };


    return (
        <Modal dialogClassName='wide-modal' show={showModal} onHide={toggleModal}>
            <Container>
            <Row>
                <Col className='profile-background-img-container position-relative'>
                    <button style={{ zIndex: "3" }} onClick={toggleModal} className="modal-close p-1 mt-2 mr-2 text-center" type="button" data-dismiss="modal" aria-label="Close">
                        <X color='black' className='full-center' size={30} />
                    </button>
                    {blog.background_image && <Image src={getBlogBackground()} className='w-100 full-center' />}
                    <div className='position-absolute p-3 text-left' style={{ top: "0", left: "0"}}>
                        <h2 className='text-white trip-heading'>{blog.name}</h2>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col className='p-0'>
                    <TripOverview folder={blog} currentFolder={blog} setFolders={setBlogs} followView={true} />
                </Col>
            </Row>
            <Row>
                <Col>
                    <Locations ownProfile={false} blog={blog} />
                </Col>
            </Row>
            <Row>
                <Col>
                    <FolderView 
                        blogView={false} 
                        folder={blog} 
                        addAble={!!user && !!blog && user.id !== blog.user_id} 
                        folders={folders} 
                        followView={true}
                        ownProfile={false}
                    />
                </Col>
            </Row>
        </Container>
        <Modal.Footer>
                <Button variant="secondary" onClick={toggleModal}>
                    Close
                </Button>
        </Modal.Footer>
        </Modal>
    )
};

export default BlogModal;

