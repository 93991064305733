import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { URL } from '../../config';
import { useForm } from 'react-hook-form';
import { useAuth0 } from '@auth0/auth0-react';
import { Form, Button, Col, Image } from 'react-bootstrap';
import { UserState } from '../Recoil/atoms';
import { useRecoilState } from 'recoil';
import ReactGA from 'react-ga4';
import { encrypt } from 'crypto-js/aes';
import Logo from '../../images/trottr_web_logo.svg';


const SignUpForm = () => {
    const { register, handleSubmit, reset, watch, errors } = useForm();
    const [error, setError] = useState(null);
    const { user } = useAuth0();
    const [userInfo, setUserInfo] = useRecoilState(UserState);

    const createUser = input => {
        ReactGA.event({
            category: "User Accounts",
            action: "User Creates Account"
        })
        if (errors) {
            reset({})
        }
        const {
            firstName,
            lastName,
            username,
        } = input;
        input.username = input.username.toLowerCase();
        input.email = user.email;
        // console.log('input with email: ', input)
        const credentials = encrypt(JSON.stringify(input), 'M!zzT33tz$33').toString();
        reset({});
        axios.post(`${URL}/signup`, { data: credentials })
            .then((res) => {
                if (res.status === 201) {
                    setUserInfo(res.data[0]);
                } else {
                    console.log('sign up error: ', err.response.status);
                    setError(err.response.status);
                }
            })
            .catch(err => {
                console.log('sign up error: ', err)
                if (!!err.response) {
                    console.log('sign up status: ', err.response.status);
                    setError(err.response.status);
                } else {
                    setError(501);
                }

            })
    };

    return (
        <div className="main">
                {!!error && (<div>{error}</div>)}
                <Col className='col-md-4 offset-md-4 pt-5 mb-3'>
                    <Image className='w-100' src={Logo} />
                </Col>
                <Form className="col-md-6 offset-md-3 pt-3" onSubmit={handleSubmit(createUser)}>
                    <h1 className="mb-3">Finish Sign Up!</h1>
                    <Form.Row>
                        <Form.Group as={Col} controlId="formGridFirstname">
                            <Form.Control
                                type="text"
                                name="firstName" 
                                placeholder="First Name"
                                aria-invalid={errors.firstName ? "true" : "false"}
                                ref={register({
                                    required: "You must enter a first name."
                                })}
                            />
                        </Form.Group>
                        <Form.Group as={Col} controlId="formGridLastname">
                            <Form.Control 
                                type="text" 
                                name="lastName" 
                                placeholder="Last Name"
                                aria-invalid={errors.lastName ? "true": "false"}
                                ref={register({
                                    required: "You must enter a last name."
                                })}
                            />
                        </Form.Group>
                    </Form.Row>
                        {errors.firstName && errors.firstName.type === "required" && (
                            <div className="mt-0 pt-0 mb-2">
                                <span role="alert" className="text-danger mt-0 pt-0">First name is required and must be text.</span>
                            </div>
                        )}
                        {errors.lastName && errors.lastName.type === "required" && (
                            <div className="mt-0 pt-0 mb-2">
                                <span role="alert" className="text-danger mt-0 pt-0">Last name is required and must be text.</span>
                            </div>
                        )}
                        <Form.Group controlId="formGridUsername">
                            <Form.Control
                                name="username"
                                placeholder="New username (lowercase only)"
                                autoCapitalize='none'
                                aria-invalid={errors.username ? "true" : "false"}
                                ref={register({
                                    required: "You must provide a valid username.",
                                    validate: value => !value.includes(' ') || 'Username cannot contain spaces.',
                                    pattern: {
                                        value: /^[a-z0-9._]*$/,
                                        message: "Username must be lowercase and can only include letters, numbers, underscores, and periods."
                                    }
                                })}
                                value={watch('username') || ''}  // Ensures the value is controlled by the form state
                                onChange={(e) => {
                                    const lowercasedValue = e.target.value.toLowerCase();
                                    e.target.value = lowercasedValue;
                                }}
                            />
                        </Form.Group>
                        {errors.username && (
                            <div className="mt-0 pt-0 mb-2">
                                <span role="alert" className="text-danger mt-0 pt-0">{errors.username.message}</span>
                            </div>
                        )}
                    <Button size="lg" className="rounded-pill btn-secondary" variant="primary" type="submit">
                        Sign Up
                    </Button>
                </Form>
                {error === 500 && (
                    <div>
                        <h5 className="text-danger m-2">Sorry, we cannot process your request at this time.</h5>
                    </div>
                )}
                {error === 501 && (
                    <div>
                        <h5 className="text-danger m-2">Sorry, we cannot process your request at this time.</h5>
                    </div>
                )}
                {error === 409 && (
                    <div>
                        <h5 className="text-danger m-2">An account is already associated with that username or email.</h5>
                    </div>
                )}
            </div>
    );
};

export default SignUpForm;