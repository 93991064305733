import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { useNavigate, useLocation } from 'react-router-dom';
import Follow from '../FollowView/Follow';
import Discover from './Discover';
import SearchBlogs from './SearchBlogs';


const Search = () => {
    const navigate = useNavigate();
    const location = useLocation();


    const formatViewHeading = (targetLocation) => {
        if (targetLocation === location.pathname) {
            return 'solid black';
        } else {
            return "none";
        }
    }

    return (
        <div className='main'>
                <Row className='m-0 p-0'>
                    <Col className='text-center mb-0'>
                        <h4>Discover</h4>
                    </Col>
                </Row>
                <Row className='mb-2 mt-0 mw-100 pl-2 mr-0 ml-0'>
                    <Col className='col-md-4'>
                        <h6 className='cursor-pointer' style={{ borderBottom: formatViewHeading('/search/albums') }} onClick={() => navigate('/search/albums')}>Albums</h6>
                    </Col>
                    <Col className='col-md-4'>
                        <h6 className='cursor-pointer' style={{ borderBottom: formatViewHeading('/search/users') }} onClick={() => navigate('/search/users')}>Users</h6>
                    </Col>
                    <Col className='col-md-4'>
                        <h6 className='cursor-pointer' style={{ borderBottom: formatViewHeading('/search/places') }} onClick={() => navigate('/search/places')}>Places</h6>
                    </Col>
                </Row>
                {location.pathname === '/search/places' && <Discover postView={false} />}
                {location.pathname === '/search/users' && <Follow />}
                {location.pathname === '/search/albums' && <SearchBlogs />}
        </div>
    )
}

export default Search;