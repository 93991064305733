import React, { useEffect, useState } from 'react';
import { Row, Col, Modal, Button, DropdownButton, Image } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { URL, IMG_URL } from '../../config';
import axios from 'axios';
import { getUserInfo } from '../apiHelpers';
import DropdownItem from 'react-bootstrap/esm/DropdownItem';
import { useRecoilState } from 'recoil';
import { UserState } from '../Recoil/atoms';
import ReactGA from 'react-ga4';


const FollowCount = ({ user, setUser }) => {
    const [showFollowModal, setShowFollowModal] = useState(false);
    const [showFollowers, setShowFollowers] = useState(true);
    const [followingUser, setFollowingUser] = useRecoilState(UserState);
    const ownProfile = user.id === followingUser.id ? true : false;
    const navigate = useNavigate();
    const [followers, setFollowers] = useState([]);
    const [following, setFollowing] = useState([]);

    const toggleFollowModal = () => setShowFollowModal(show => !show);

    const toggleFollowView = () => setShowFollowers(show => !show);

    const validFollower = () => {
        if (user.following) {
            const idSet = new Set(user.following.map(user => user.id));
            return idSet.has(followingUser.id);
        }
    }

    const getFollowers = async () => {
        // const getFollowers = async () => {
        //     let followers = [];
        //     let initial = user;
        //     if (!!user && !!user.followers) {
        //         const userIds = Object.keys(user.followers);
        //         for (let i = 0; i < userIds.length; i++) {
        //             let follower = {... user.followers[userIds[i]]};
        //             follower.id = userIds[i];
        //             const res = await axios.get(`${URL}/users/find/${follower.username.toLowerCase()}`);
        //             let updatedFollower = await res.data[0];
        //             if (!!updatedFollower) {
        //                 updatedFollower.id = follower.id;
        //                 followers.push(updatedFollower);
        //             }
        //         }
        //     }
            
        //     return followers;
        // }

        // const followers = await getFollowers();
        console.log(user.followers);
        setFollowers(user.followers);
    }
    

    const getFollowing = async () => {
        // const getFollowing = async () => {
        //     let following = [];
        //     if (!!user.following) {
        //         const userIds = Object.keys(user.following);
        //         for (let i = 0; i < userIds.length; i++) {
        //             let followed = {... user.following[userIds[i]]};
        //             followed.id = userIds[i];
        //             const res = await axios.get(`${URL}/users/find/${followed.username.toLowerCase()}`);
        //             let updatedFollowed = await res.data[0];
        //             if (!!updatedFollowed) {
        //                 updatedFollowed.id = followed.id
        //                 following.push(updatedFollowed);
        //             }
        //         }
        //     }
        //     return following;
        // }
        // const following = await getFollowing();
        // const url = `${URL}/user/${user.id}/following`;
        // const res = await axios.get(url);
        // console.log('res from get following: ', res);
        setFollowing(user.following);
    }

    
    
    useEffect(() => {
        if (!!user && !!showFollowModal) {
            getFollowers();
            getFollowing();
        }
    }, [showFollowModal, user]);

    const navigateToUserProfile = (username) => {
        if (!ownProfile) {
            ReactGA.event({
                category: 'Follow',
                action: 'User views a mutual connections profile'
            })
        }
        setShowFollowModal(false);
        navigate(`/${username.toLowerCase()}`);
    }

    const removeFollower = async (follower, e) => {
        console.log('follower: ', follower);
        console.log('user: ')
        e.preventDefault();
        ReactGA.event({
            category: 'Follow',
            action: 'User removes Follower'
        })
        let followCount = Object.keys(user.followers).length || 0;
        followCount -= 1;
        const url = `${URL}/follow/delete/follower/${follower.id}/user/${user.id}`;
        const res = await axios.delete(url, { followCount })
        await getUserInfo(user.email, setUser);
        return res;
    }

    const unfollowUser = async (followed, e) => {
        console.log('followed: ', followed.id);
        console.log('user: ', user.id);
        e.preventDefault();
        ReactGA.event({
            category: 'Follow',
            action: 'User unfollows another user'
        })
        const url = `${URL}/follow/delete/follower/${user.id}/user/${followed.id}`;
        const res = await axios.delete(url)
        await getUserInfo(user.email, setUser);
        return res;
    }

    const followBack = async (followingUser, e) => {
        e.preventDefault();
        ReactGA.event({
            category: 'Follow',
            action: 'User requests to follow another user back'
        })
        const follower = {
            username: user.username,
            firstName: user.first_name,
            lastName: user.last_name,
            private: user.private,
            profilePic: user.profile_image
        }
        const followed = {
            username: followingUser.username,
            firstName: followingUser.first_name,
            lastName: followingUser.last_name,
            private: followingUser.private,
            profilePic: followingUser.profile_image
        }

        if (followingUser.private === true) {
            const url = `${URL}/follow/request/${followingUser.id}/follower/${user.id}`;
            const res = await axios.post(url, { followed, follower });
            console.log('res from follow request: ', res);
            await getUserInfo(user.email, setUser);
            // await getUserInfo(followingUser.email, setFollowingUser);
            return res;
        } else {
            let followCount = Object.keys(followingUser.followers).length || 0;
            followCount += 1
            const url = `${URL}/follow/accept/${followingUser.id}/follower/${user.id}`;
            const res = await axios.post(url, { follower, followed, followCount });
            console.log('Res from follow accept: ', res);
            await getUserInfo(user.email, setUser);
            // await getUserInfo(followingUser.email, setFollowingUser);

            return res;
        }
    }

    const followMutualFriend = async (mutualFriend, e) => {
        e.preventDefault();
        ReactGA.event({
            category: 'Follow',
            action: 'User requests to follow mutual connection'
        })
        const follower = {
            username: followingUser.username,
            firstName: followingUser.first_name,
            lastName: followingUser.last_name,
            private: followingUser.private,
            profilePic: followingUser.profile_image
        }
        const followed = {
            username: mutualFriend.username,
            firstName: mutualFriend.first_name,
            lastName: mutualFriend.last_name,
            private: mutualFriend.private,
            profilePic: mutualFriend.profile_image
        }

        if (followingUser.private === true) {
            const url = `${URL}/follow/request/${mutualFriend.id}/follower/${followingUser.id}`;
            const res = await axios.post(url, { followed, follower });
            console.log('res from follow request: ', res);
            await getUserInfo(followingUser.email, setFollowingUser);
            // await getUserInfo(followingUser.email, setFollowingUser);
            return res;
        } else {
            const url = `${URL}/follow/accept/${mutualFriend.id}/follower/${followingUser.id}`;
            const res = await axios.post(url, { follower, followed });
            console.log('Res from follow accept: ', res);
            await getUserInfo(user.email, setUser);
            // await getUserInfo(followingUser.email, setFollowingUser);

            return res;
        }
    } 

    const viewFollowers = () => {
        if (ownProfile || validFollower) {
            setShowFollowers(true);
            toggleFollowModal();
        }
    }

    const viewFollowing = () => {
        if (ownProfile || validFollower()) {
            setShowFollowers(false);
            toggleFollowModal();
        }
    }

    const getProfilePic = (user) => {
        if (!!user.profile_image) {
            return `url(${IMG_URL}/${user.profile_image.path})`;
        } else {
            return "none"
        }
    }

    const isFollowing = (followingUser, followed) => {
        const idSet = new Set(followingUser.followers.map(user => user.id));
        return idSet.has(followed.id);
    }

    return (
        <>
            <Row>
                <Col className='col-md-6 text-center' style={{ cursor: ownProfile || validFollower ? "pointer" : "default" }} onClick={viewFollowers} >
                    <h5 className='text-dark mb-0'>{!!user.followers ? Object.keys(user.followers).length : 0}</h5>
                    <p className='text-dark font-weight-light mb-0'>followers</p>
                </Col>
                <Col className='col-md-6 text-center' style={{ cursor: ownProfile || validFollower ? "pointer" : "default" }} onClick={viewFollowing}>
                    <h5 className='text-dark mb-0'>{!!user.following ? Object.keys(user.following).length : 0}</h5>
                    <p className='text-dark font-weight-light mb-0'>following</p>
                </Col>
            </Row>
            <Modal show={showFollowModal} onHide={toggleFollowModal}>
            <Modal.Header className='bg-light' closeButton>
                <Row className='w-100 m-0 p-0'>
                    <Col className='text-center col-12 m-0 p-0'>
                        <h3 className='m-0 p-0'>{user.username}</h3>
                    </Col>
                </Row>
            </Modal.Header>
            <Modal.Body>
                <Row className='mb-0 w-100'>
                    <Col className='col-md-6 text-center'>
                        <h5 className='cursor-pointer' style={{ borderBottom: showFollowers ? "solid black" : "none" }} onClick={() => toggleFollowView()}>{followers.length} Followers</h5>
                    </Col>
                    <Col className='col-md-6 text-center'>
                        <h5 className='cursor-pointer' style={{ borderBottom: !showFollowers ? "solid black" : "none" }} onClick={() => toggleFollowView()}>{following.length} Following</h5>
                    </Col>
                </Row>
                {!!ownProfile ? (!!showFollowers && followers.length === 0 && (
                    <h6 className='text-dark'>You have no followers.</h6>
                )) : (
                    !!showFollowers && followers.length === 0 && (
                    <h6 className='text-dark'>This user has no followers.</h6>
                ))}
                {!!ownProfile ? (!!showFollowers && followers.length > 0 && followers.map((follower, i) => (
                        <Row className='border-bottom' key={i}>
                            <Col className='col-2 p-0 mt-1 mb-1 pl-3'>
                                <div className='feed-profile-pic-container mr-0 pr-0' onClick={() => navigateToUserProfile(follower.username)}>
                                    {follower.profile_image && <Image src={`${IMG_URL}/${follower.profile_image.path}`} loading='lazy' className='profile-image' />}
                                </div>
                            </Col>
                            <Col className='col-5 col-md-6 text-left p-0 mt-1'>
                                <div className='d-inline'>
                                    <p className='text-dark mb-0 d-inline'><span className='text-primary font-weight-bold cursor-pointer' onClick={() => navigateToUserProfile(follower.username)} >{follower.username} </span></p>
                                    {!user.following || (!!user.following && !isFollowing(user, follower)) && ((!!user.pending_following && !user.pending_following[follower.id]) || !user.pending_following) && (
                                        <p className='text-secondary font-weight-bold mr-1 d-inline mb-0' style={{ cursor: "pointer" }} onClick={(e) => followBack(follower, e)}><span className='text-dark'>-</span> Follow</p>
                                    )}
                                    {!!user.pending_following && user.pending_following[follower.id] && (
                                        <Button size='sm' className='btn-light ml-2' disabled>Requested</Button>
                                    )}
                                </div>
                                <p className='text-gray font-weight-light mb-1'>{`${follower.first_name} ${follower.last_name}`}</p>
                            </Col>
                            <Col className='col-md-4 right-align mt-2 mb-1 pl-0'>
                                <Button size='sm' className='btn-light mr-1 mb-1' onClick={(e) => removeFollower(follower, e)}>Remove</Button>
                            </Col>
                        </Row>
                    ))) : (!!showFollowers && followers.length > 0 && followers.map((follower, i) => (
                        <Row className='border-bottom' key={i}>
                            <Col className='col-2 p-0 mt-1 mb-1 pl-3'>
                                <div className='feed-profile-pic-container mr-0 pr-0' onClick={() => navigateToUserProfile(follower.username)}>
                                    {follower.profile_image && <Image src={`${IMG_URL}/${follower.profile_image.path}`} loading='lazy' className='profile-image' />}
                                </div>
                            </Col>
                            <Col className='col-5 col-md-6 text-left p-0 mt-1'>
                                <div className='d-inline'>
                                    <p className='text-dark mb-0 d-inline'><span className='text-primary font-weight-bold cursor-pointer' onClick={() => navigateToUserProfile(follower.username)} >{follower.username} </span></p>
                                </div>
                                <p className='text-gray font-weight-light mb-1'>{`${follower.first_name} ${follower.last_name}`}</p>
                            </Col>
                            <Col className='col-md-4 right-align mt-2 mb-1 pl-0'>
                                {followingUser.id != follower.id && (!followingUser.following || (!!followingUser.following && !isFollowing(followingUser, follower))) && ((!!followingUser.pending_following && !followingUser.pending_following[follower.id]) || !followingUser.pending_following) && (
                                    <Button size='sm' className='btn-secondary mr-1 mb-1' onClick={(e) => followMutualFriend(follower, e)} >Follow</Button>
                                    
                                )}
                                {followingUser.id !== follower.id && !!followingUser.pending_following && followingUser.pending_following[follower.id] && (
                                    <Button size='sm' className='btn-light mr-1 mb-1' disabled>Requested</Button>
                                )}
                                {followingUser.id !== follower.id && !!followingUser.following && isFollowing(followingUser, follower) && (
                                    <Button size='sm' className='btn-primary mr-1 mb-1' onClick={() => navigateToUserProfile(follower.username)} >Following</Button>
                                )}
                            </Col>
                        </Row>
                    )
                    
                ))}
                {!!ownProfile ? (!showFollowers && following.length === 0 && (
                    <h6 className='text-dark'>You are not following anyone.</h6>
                )) : (!showFollowers && following.length === 0 && (
                    <h6 className='text-dark'>This user has no followers.</h6>
                ))}
                {!!ownProfile ? (!showFollowers && following.length > 0 && following.map((followed, i) => (
                    <Row className='border-bottom'key={i}>
                        <Col className='col-2 p-0 mt-1 mb-1 pl-3'>
                            <div className='feed-profile-pic-container mr-0 pr-0' onClick={() => navigateToUserProfile(followed.username)}>
                                {followed.profile_image && <Image src={`${IMG_URL}/${followed.profile_image.path}`} loading='lazy' className='profile-image' />}
                            </div>
                        </Col>
                        <Col className='col-5 col-md-6 text-left p-0 mt-1'>
                            <p className='text-dark mb-0'><span className='text-primary font-weight-bold cursor-pointer' onClick={() => navigateToUserProfile(followed.username)} >{followed.username}</span></p>
                            <p className='text-gray font-weight-light mb-1'>{`${followed.first_name} ${followed.last_name}`}</p>
                        </Col>
                        <Col className='col-5 col-md-4 right-align mt-2 mb-1 pl-0'>
                            <DropdownButton size='sm' className='btn-following' title='Following'>
                                <DropdownItem size='sm' className='bg-light' onClick={(e) => unfollowUser(followed, e)} >Unfollow</DropdownItem>
                            </DropdownButton>
                        </Col>
                    </Row>
                ))) : (!showFollowers && following.length > 0 && following.map((followed, i) => (
                    <Row className='border-bottom' key={i}>
                        <Col className='col-2 p-0 mt-1 mb-1 pl-3'>
                            <div className='feed-profile-pic-container mr-0 pr-0' onClick={() => navigateToUserProfile(followed.username)}>
                                {followed.profile_image && <Image src={`${IMG_URL}/${followed.profile_image.path}`} loading='lazy' className='profile-image' />}
                            </div>
                        </Col>
                        <Col className='col-5 col-md-6 text-left p-0 mt-1'>
                            <p className='text-dark mb-0'><span className='text-primary font-weight-bold cursor-pointer' onClick={() => navigateToUserProfile(followed.username)} >{followed.username}</span></p>
                            <p className='text-gray font-weight-light mb-1'>{`${followed.first_name} ${followed.last_name}`}</p>
                        </Col>
                        <Col className='col-5 col-md-4 right-align mt-2 mb-1 pl-0'>
                            {followingUser.id != followed.id && (!followingUser.following || (!!followingUser.following && !isFollowing(followingUser, followed))) && ((!!followingUser.pending_following && !followingUser.pending_following[followed.user_id]) || !followingUser.pending_following) && (
                                    <Button size='sm' className='btn-secondary mr-1 mb-1' onClick={(e) => followMutualFriend(followed, e)} >Follow</Button>
                                    
                            )}
                            {followingUser.id !== followed.id && !!followingUser.pending_following && followingUser.pending_following[followed.id] && (
                                <Button size='sm' className='btn-light mr-1 mb-1' disabled>Requested</Button>
                            )}
                            {followingUser.id !== followed.id && !!followingUser.following && isFollowing(followingUser, followed) && (
                                <Button size='sm' className='btn-primary mr-1 mb-1' onClick={() => navigateToUserProfile(followed.username)} >Following</Button>
                            )}
                        </Col>
                    </Row>
                ))
                )}
            </Modal.Body>
            <Modal.Footer className='bg-light'>
                <Button variant="secondary" onClick={toggleFollowModal}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
        </>
    )
};

export default FollowCount;