import React, { useState } from 'react';
import { useRecoilState } from 'recoil';
import { folderState, UserState, blogState } from '../Recoil/atoms.js';
import { Container, Row, Col } from 'react-bootstrap';
import SignUpForm from '../HomePage/SignUpForm.js';
import BlogCard from './BlogCard.js';
import { Plus } from 'lucide-react';
import { useNavigate } from 'react-router-dom';


const MyBlogs = () => {
    const [currentFolder, setCurrentFolder] = useState({});
    const [folders, setFolders] = useRecoilState(folderState);
    const [userInfo, setUserInfo] = useRecoilState(UserState);
    const [blogs, setBlogs] = useRecoilState(blogState);

    const navigate = useNavigate();

  
    return (
        <>
        {userInfo === undefined && (
            <SignUpForm />
        )}
        {!!userInfo && !userInfo.content_creator && (
            <Container>
                <Row>
                    <Col>
                        <h4 className='mb-3' style={{ textDecoration: "underline" }}>You do not currently have sharing permissions.</h4>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <h6 className='text-secondary mb-3'>The ability to share on Trottr is invite only. We do this to ensure the quality of content on our site.</h6>
                        <h6 className='text-secondary mb-3'>We encourage you to use the other features on our site including the "Dream" tab where you can bookmark posts that have inspired you or the "Plan" tab where you can create detailed itineraries in collaboration with your friends.</h6>
                        <h6 className='text-secondary mb-3'>If you have not been invited but would like to share you can submit a request via email to support@trottr.com.</h6>
                        <h6 className='text-secondary mb-5'>Please include any relevant social media handles to help us evaluate your account.</h6>
                    </Col>
                </Row>
            </Container>
        )}
        {!!userInfo && !!userInfo.content_creator
            && (
            <Container>
            <Row>
                <Col>
                    <Row>
                        <Col>
                            <div className='cursor-pointer' onClick={() => navigate('/post')}>
                                <Plus color='#424242' size={64} />
                                <p className='text-body'>New Trip Guide</p>
                            </div>
                        </Col>
                    </Row>
                    <Row className='mt-3'>
                        <Col>
                            <h2>My Trip Guides:</h2>
                        </Col>
                    </Row>
                    <Row className="mb-3">
                            {!!blogs && blogs.map((folder, i) => {
                                return (
                                    <Col className='col-md-6 col-12 pr-1 pl-1' key={i}>
                                        <BlogCard folder={folder} setCurrentFolder={setCurrentFolder} currentFolder={currentFolder} blogView={true} />
                                    </Col>
                                )
                            })}
                    </Row>
                </Col>
        </Row>
        </Container>
        )}
        </>
    );
};

export default MyBlogs;