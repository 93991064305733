import React from 'react';
import { Bell } from 'lucide-react';


const NotificationBell = ({ notifications, navLinkColor }) => {

    return (
        <div className='m-0 p-0 position-relative'>
            {/* <Image className='nav-icon' style={{ width: "1.5em" }} src={Bell} /> */}
            <Bell color={navLinkColor('/notifications')} size={32} />
            {!!notifications > 0 && (
                <div className='notification-number-container text-white'>
                    <p className='notification-number'>{notifications}</p>
                </div>
            )}
        </div>
    )
}

export default NotificationBell;