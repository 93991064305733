import React, { useEffect, useState } from 'react';
import { Row, Col, Button, Image, Form, InputGroup } from 'react-bootstrap';
import axios from 'axios';
import InstagramIcon from '../../images/instagram_icon.png';
import WebIcon from '../../images/web-icon.svg';
import YoutubeIcon from '../../images/youtube-icon.png';
import TikTokIcon from '../../images/tiktok-logo.png';
import { URL } from '../../config';
import { getUserInfo } from '../apiHelpers';
import ReactGA from 'react-ga4';



const SocialLinks = ({ user, setUser, followView }) => {
    const [instaHandle, setInstaHandle] = useState(null);
    const [instaHandleEditable, setInstaHandleEditable] = useState(false);
    const [webLink, setWebLink] = useState('');
    const [webLinkEditable, setWebLinkEditable] = useState(false);
    const [youtubeHandle, setYoutubeHandle] = useState('');
    const [youtubeEditable, setYoutubeEditable] = useState(false);
    const [tikTokHandle, setTikTokHandle] = useState('');
    const [tikTokEditable, setTikTokEditable] = useState(false);

    const getInstaHandle = () => {
        if (!!user.social_links && !!user.social_links.instagram) {
            return `https://instagram.com/${user.social_links.instagram}`;
        }
    };

    const getWebLink = () => {
        if (!!user.social_links && !!user.social_links.website) {
            return `https://www.${user.social_links.website}`;
        }
    };

    const getYoutubeLink = () => {
        if (!!user.social_links && user.social_links.youtube) {
            return `https://youtube.com/@${user.social_links.youtube}`;
        }
    };

    const getTikTokLink = () => {
        if (!!user.social_links && user.social_links.tiktok) {
         return `https://www.tiktok.com/@${user.social_links.tiktok}`;
        }
    };

    const openLink = (getLink) => {
        ReactGA.event({
            category: 'Follow',
            action: 'User views another user social link'
        })
        const link = getLink();
        if (!!link) {
            window.open(link);
        }
    };

    const submitWebLink = async (e) => {
        e.preventDefault();
        ReactGA.event({
            category: 'Profile',
            action: 'User Adds Website Link to Profile'
        })
        const url = `${URL}/users/${user.id}/profile/website`;
        const res = await axios.post(url, { url: webLink });
        if (res.status === 200) {
            getUserInfo(user.email, setUser);
        }
        setWebLinkEditable(false);

    };

    const submitTikTokLink = async (e) => {
        e.preventDefault();
        ReactGA.event({
            category: 'Profile',
            action: 'User Adds TikTok Link to Profile'
        })
        const url = `${URL}/users/${user.id}/profile/tiktok`;
        const res = await axios.post(url, { url: tikTokHandle });
        if (res.status === 200) {
            getUserInfo(user.email, setUser);
        }
        setTikTokEditable(false);

    };

    const submitInstaHandle = async (e) => {
        e.preventDefault();
        ReactGA.event({
            category: 'Profile',
            action: 'User Adds Instagram Link to Profile'
        })
        const url = `${URL}/users/${user.id}/profile/instagram`;
        const res = await axios.post(url, { handle: instaHandle })
        if (res.status === 200) {
            getUserInfo(user.email, setUser);
        }
        setInstaHandleEditable(false);
    };

    const submitYoutubeHandle = async (e) => {
        e.preventDefault();
        ReactGA.event({
            category: 'Profile',
            action: 'User Adds YouTube Link to Profile'
        })
        const url = `${URL}/users/${user.id}/profile/youtube`;
        const res = await axios.post(url, { url: youtubeHandle });
        if (res.status === 200) {
            getUserInfo(user.email, setUser);
        }
        setYoutubeEditable(false);
    };

    const showYoutubeInput = () => {
        setYoutubeEditable(true);
        setInstaHandleEditable(false);
        setWebLinkEditable(false);
        setTikTokEditable(false);
    };

    const showInstaInput = () => {
        setInstaHandleEditable(true);
        setYoutubeEditable(false);
        setWebLinkEditable(false);
        setTikTokEditable(false);
    };

    const showWebsiteInput = () => {
        setWebLinkEditable(true);
        setYoutubeEditable(false);
        setInstaHandleEditable(false);
        setTikTokEditable(false);
    };

    const showTikTokInput = () => {
        setTikTokEditable(true);
        setYoutubeEditable(false);
        setWebLinkEditable(false);
        setInstaHandleEditable(false);
    };

    useEffect(() => {
        if (!!user.social_links && user.social_links.website) {
            setWebLink(user.social_links.website)   
        }
        if (!!user.social_links && user.social_links.instagram) {
            setInstaHandle(user.social_links.instagram);
        }
        if (!!user.social_links && user.social_links.youtube) {
            setYoutubeHandle(user.social_links.youtube);
        }
        if (!!user.social_links && user.social_links.tiktok) {
            setTikTokHandle(user.social_links.tiktok);
        }
    }, [user])

    return (
        <Row className='mr-0 ml-0 mt-0 mb-2 p-0 mw-100'>
            <Col className='pl-0'>
                <Row className='m-0 p-0'>
                    <div className='p-0 m-0'>
                        <div>
                            {(!followView || getWebLink()) && (
                                <Image className='filter-primary mr-2' 
                                    style={{ width: "2rem", cursor: "pointer" }} 
                                    src={WebIcon} 
                                    alt="link to website"
                                    onClick={(e) => openLink(getWebLink)}
                                />
                            )}
                        </div>
                        <div>
                            {!webLinkEditable && !followView && (
                                <Button
                                    className='btn-transparent text-primary pl-0'
                                    style={{ textDecoration: "underline" }}
                                    onClick={() => showWebsiteInput()}
                                >
                                    Edit
                                </Button>
                                ) 
                            }
                        </div>
                    </div>
                    <div className='m-0 p-0'>
                        <div>
                            {(!followView || getInstaHandle()) && (
                            <Image className='filter-primary mr-2' 
                                    style={{ width: "2rem", cursor: "pointer" }} 
                                    src={InstagramIcon} 
                                    alt='link to website'
                                    onClick={(e) => openLink(getInstaHandle)}
                                />
                            )}
                        </div>
                        <div>
                            {!instaHandleEditable && !followView && (
                                <Button
                                    className='btn-transparent text-primary pl-0'
                                    style={{ textDecoration: "underline" }}
                                    onClick={() => showInstaInput()}
                                >
                                    Edit
                                </Button>
                                ) 
                            }
                        </div>
                    </div>
                    <div className='m-0 p-0'>
                        <div>
                            {(!followView || getYoutubeLink()) && (
                                <Image className='filter-primary mr-2' 
                                    style={{ width: "2rem", cursor: "pointer" }} 
                                    src={YoutubeIcon} 
                                    alt='link to YouTube'
                                    onClick={(e) => openLink(getYoutubeLink)}
                                />
                            )}
                        </div>
                        <div>
                            {!youtubeEditable && !followView && (
                                <Button
                                    className='btn-transparent text-primary pl-0'
                                    style={{ textDecoration: "underline" }}
                                    onClick={() => showYoutubeInput()}
                                >
                                    Edit
                                </Button>
                            )}
                        </div>
                    </div>
                    <div className='m-0 p-0'>
                        <div>
                            {(!followView || getTikTokLink()) && (
                                <Image className='filter-primary mr-2' 
                                    style={{ width: "2rem", cursor: "pointer" }} 
                                    src={TikTokIcon} 
                                    alt='link to TikTok'
                                    onClick={(e) => openLink(getTikTokLink)}
                                />
                            )}
                        </div>
                        <div>
                            {!tikTokEditable && !followView && (
                                <Button
                                    className='btn-transparent text-primary pl-0'
                                    style={{ textDecoration: "underline" }}
                                    onClick={() => showTikTokInput()}
                                >
                                    Edit
                                </Button>
                            )}
                        </div>
                    </div>
                </Row>
                <Row className='m-0 p-0'>
                    {!!webLinkEditable && !followView && (
                        <Form className='mb-3 w-75' onSubmit={submitWebLink} >
                            <Form.Group className='text-left mt-0 mb-1'>
                                <Col className='m-0 p-0'>
                                    <Row className='m-0 p-0'>
                                        <InputGroup>
                                            <InputGroup.Text>https://</InputGroup.Text>
                                            <Form.Control className='ml-0' placeholder='example.com' value={webLink} onChange={(e) => setWebLink(e.target.value)} id='webLinkInput' />
                                        </InputGroup>
                                    </Row>
                                    <Row className='m-0 p-0 position-relative mt-1'>
                                        <div className='position-absolute' style={{ right: "0" }}>
                                            <Button className='btn-secondary pt-1 pb-1 mb-1 mr-1' size='sm' onClick={() => setWebLinkEditable(false)}>Cancel</Button>
                                            <Button className='btn-primary pt-1 pb-1 mb-1 mr-0' type="submit" size="sm">Save</Button>
                                        </div>
                                    </Row>
                                </Col>
                            </Form.Group>
                        </Form>
                    )}
                    {!!instaHandleEditable && !followView && (
                        <Form className='mb-3 w-75' onSubmit={submitInstaHandle}>
                            <Form.Group as={Row} className='text-left mt-0 mb-1 ml-0'>
                                <Col className='m-0 p-0'>
                                    <Row className='m-0 p-0'>
                                        <InputGroup>
                                            <InputGroup.Text>https://instagram.com/</InputGroup.Text>
                                            <Form.Control className='ml-0' placeholder='your_handle' value={instaHandle} onChange={(e) => setInstaHandle(e.target.value)} id='webLinkInput' />
                                        </InputGroup>
                                    </Row>
                                    <Row className='m-0 p-0 position-relative mt-1'>
                                        <div className='position-absolute' style={{ right: "0" }}>
                                            <Button className='btn-secondary pt-1 pb-1 mb-1 mr-1' size='sm' onClick={() => setInstaHandleEditable(false)}>Cancel</Button>
                                            <Button className='btn-primary pt-1 pb-1 mb-1 mr-0' type="submit" size="sm">Save</Button>
                                        </div>
                                    </Row>
                                </Col>
                            </Form.Group>
                        </Form>
                    )}
                    {!!youtubeEditable && !followView && (
                        <Form className='mb-3 w-75' onSubmit={submitYoutubeHandle}>
                            <Form.Group as={Row} className='text-left mt-0 mb-1 ml-0'>
                                <Col className='m-0 p-0'>
                                    <Row className='m-0 p-0'>
                                        <InputGroup>
                                            <InputGroup.Text>https://youtube.com/@</InputGroup.Text>
                                            <Form.Control className='ml-0' placeholder='your_handle' value={youtubeHandle} onChange={(e) => setYoutubeHandle(e.target.value)} id='webLinkInput' />
                                        </InputGroup>
                                    </Row>
                                    <Row className='m-0 p-0 position-relative mt-1'>
                                        <div className='position-absolute' style={{ right: "0" }}>
                                            <Button className='btn-secondary pt-1 pb-1 mb-1 mr-1' size='sm' onClick={() => setYoutubeEditable(false)}>Cancel</Button>
                                            <Button className='btn-primary pt-1 pb-1 mb-1 mr-0' type="submit" size="sm">Save</Button>
                                        </div>
                                    </Row>
                                </Col>
                            </Form.Group>
                        </Form>
                    )}
                    {!!tikTokEditable && !followView && (
                        <Form className='mb-3 w-75' onSubmit={submitTikTokLink}>
                            <Form.Group as={Row} className='text-left mt-0 mb-1 ml-0'>
                                <Col className='m-0 p-0'>
                                    <Row className='m-0 p-0'>
                                        <InputGroup>
                                            <InputGroup.Text>https://www.tiktok.com/@</InputGroup.Text>
                                            <Form.Control className='ml-0' placeholder='your_handle' value={tikTokHandle} onChange={(e) => setTikTokHandle(e.target.value)} id='webLinkInput' />
                                        </InputGroup>
                                    </Row>
                                    <Row className='m-0 p-0 position-relative mt-1'>
                                        <div className='position-absolute' style={{ right: "0" }}>
                                            <Button className='btn-secondary pt-1 pb-1 mb-1 mr-1' size='sm' onClick={() => setTikTokEditable(false)}>Cancel</Button>
                                            <Button className='btn-primary pt-1 pb-1 mb-1 mr-0' type="submit" size="sm">Save</Button>
                                        </div>
                                    </Row>
                                </Col>
                            </Form.Group>
                        </Form>
                    )}
                </Row>
            </Col>
        </Row>
    )
}

export default SocialLinks;