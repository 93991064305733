import React, { useState } from 'react';
import { folderState, UserState } from '../Recoil/atoms';
import { useRecoilState } from 'recoil';
import { Form, Col, Row, Button } from 'react-bootstrap';
import { getFolders } from '../apiHelpers';

const UpdateBlogName = ({currentFolder, folder, updateTripName, currentTripNameEditable, setCurrentTripNameEditable}) => {
    const [currentTripName, setCurrentTripName] = useState('');
    const [folders, setFolders] = useRecoilState(folderState);
    const [userInfo, setUserInfo] = useRecoilState(UserState);

    const handleUpdateTripName = async (e) => {
        e.preventDefault();
        setCurrentTripNameEditable(false);
        const res = await updateTripName(folder.id, currentTripName);
        getFolders(userInfo, setFolders);
        return res;
    }

    return (
        <>
        {!!currentTripNameEditable && (
            <Form className='mb-2 w-100' onSubmit={handleUpdateTripName}>
                <Form.Group as={Row} className='text-left mt-0 mb-1'>
                    <Col className='m-0 p-0'>
                        <Row className='m-0 p-0'>
                            <Col>
                                <Form.Control className='ml-0' size='sm' placeholder='Rename your memory' value={currentTripName} onChange={(e) => setCurrentTripName(e.target.value)} id='tripNameInput' />
                            </Col>
                        </Row>
                        <Row className='m-0 p-0 text-right mt-1'>
                            <Col className='text-right'>
                                <Button className='btn-primary pt-1 pb-1 mb-1 mr-1' type="submit" size="sm">Save</Button>
                                <Button className='btn-secondary pt-1 pb-1 mb-1 mr-0' size='sm' onClick={() => setCurrentTripNameEditable(false)}>Cancel</Button>
                            </Col>
                        </Row>
                    </Col>
                </Form.Group>
            </Form>
        )}
        </>
    )
}

export default UpdateBlogName;