import React, { useEffect, useState } from 'react';
import { Row, Col, Button, Alert, Modal } from 'react-bootstrap';
import { getFolders } from '../apiHelpers';
import { folderState } from '../Recoil/atoms';
import { useRecoilState } from 'recoil';
import ReactGA from 'react-ga4';
import Place from './Place';

const PostPopup = ({ item, showModal, setShowModal, folderName, folderId  }) => {
    const [folders, setFolders] = useRecoilState(folderState);

    const toggleModal = () => setShowModal(showModal => !showModal);


    return (
        <> 
            <Modal dialogClassName='wide-modal' show={showModal} onHide={() => setShowModal(false)}>
            <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>
                    <Row className='pr-5 pl-5 mr-3 ml-3'>
                        <Col className='col-md-6 offset-md-3'>
                            <Place item={item} followView={true} postView={true} blogView={false} folderName={folderName} folderid={folderId} />
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer className='bg-light'>
                </Modal.Footer>
            </Modal>
        </>
    )
};

export default PostPopup;