import React, { useState, useEffect } from 'react';
import FolderView from './FolderView.js';
import axios from 'axios';
import { useRecoilState } from 'recoil';
import { folderState, UserState } from '../Recoil/atoms';
import { URL } from '../../config.js';
import { Form, Button, Container, Row, Col, Accordion, Card, InputGroup, Image } from 'react-bootstrap';
import { getFolders, getUserInfo } from '../apiHelpers';
import { useAuth0 } from '@auth0/auth0-react';
import { useForm } from 'react-hook-form';
import ReactGA from 'react-ga4';
import { GOOG_API_KEY } from '../../config.js';
import DefaultImg from '../../images/trottr-trip-background.png';
import UpdateTripName from './UpdateTripName.js';
import { Trash, Pencil } from 'lucide-react';
import { useNavigate } from 'react-router-dom';


const MyDreams = ({ uploadBackground, updateTripName }) => {
    const [newFolder, setNewFolder] = useState('');
    const [showDetails, setShowDetails] = useState(false);
    const [currentFolder, setCurrentFolder] = useState({});
    const [currentIndex, setCurrentIndex] = useState(0);
    const [folders, setFolders] = useRecoilState(folderState);
    const [userInfo, setUserInfo] = useRecoilState(UserState);
    const [newFolderUser, setNewFolderUser] = useState('');
    const { user } = useAuth0();
    const { register, handleSubmit, reset, watch, errors } = useForm();
    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false);
    const [overview, setOverview] = useState(!!currentFolder && currentFolder.overview ? currentFolder.overview : '');
    const [overViewEditable, setOverviewEditable] = useState(false);
    const [currentTripNameEditable, setCurrentTripNameEditable] = useState(false);

    const navigate = useNavigate();

    const sortFolders = (folders) => {
        if (folders[0].name === "Wishlist") {
            // console.log('Wishlist is first folder');
            return folders;
        }

        for (let i = 1; i < folders.length; i++) {
            if (folders[i].name === "Wishlist") {
                let temp = folders[0];
                folders[0] = folders[i];
                folders[i] = temp;
                // console.log("Wishlist sorted");
                return folders;
            }
        }
    }

    // useEffect(() => {
    //     if (!!userInfo && userInfo !== "undefined") {
    //         getFolders(userInfo, setFolders)
    //     }
    // }, [])


    const createFolder = async (e) => {
        e.preventDefault();
        ReactGA.event({
            category: "Memories",
            action: "User Creates New Trip"
        })
        if (newFolder.length < 1) {
            setError(true);
            return;
        } else {
            setError(false);
        }
        let req = {
            folder: newFolder,
            username: userInfo.username,
            blogView: false,
            dreamView: true
        };
        if (!!userInfo) {
            const res = await axios.post(`${URL}/users/${userInfo.id}/folders`, req)
            setNewFolder('');
            if (res.status === 201) {
                getFolders(userInfo, setFolders);
            } else {
                setError(true);
            }

        }
    }
    
    const deleteItem = async (index, folderid, userid) => {
        const url = `${URL}/users/folders/deleteitem`;
        const res = await axios.put(url, { index }, {params: { folderid, userid}});
        const body = await res.data;
        const folders = sortFolders(body);
        setFolders(folders);
    }

    const deleteFolder = async (userid, folderid, e) => {
        e.preventDefault();
        const url = `${URL}/users/folders`;
        const res = await axios.delete(url, {params: { userid, folderid }});
        const body = await res.data;
        // console.log('data after folder delete: ', body)
        const folders = sortFolders(body);
        setFolders(folders);
    }

    const changeItemDate = async (index, date, folderid, userid) => {
        // console.log('updated date: ', date);
        // console.log('userid: ', userid);
        const url = `${URL}/users/folders/updateitemdate`;
        const res = await axios.put(url, { index, date }, {params: { folderid, userid}});
        const body = await res.data;
        const folders = sortFolders(body);
        setFolders(folders);
    };

    const updateItemNotes = async (index, notes, folderId) => {
        // console.log('Updated trip notes: ', notes);
        const url = `${URL}/users/folders/${folderId}/itemnotes`;
        const res = await axios.put(url, { index, notes });
        if (res.status === 200) {
            getFolders(userInfo, setFolders);
        }
    };



    const updateOverview = async (folderId, overview) => {
        // console.log('New overview: ', overview);
        const url = `${URL}/users/folders/${folderId}/overview`;
        const res = await axios.put(url, { overview });
        return res;
    };

    const getCurrentFolderPic = (picArray) => {
        const index = Math.floor(Math.random() * picArray.length);
        const item = picArray[index];
        if (!!item) {
            // console.log(`url(https://maps.googleapis.com/maps/api/place/photo?maxwidth=800&photo_reference=${item.photos[0].photo_reference}&key=${GOOG_API_KEY})`);
            return `url(https://maps.googleapis.com/maps/api/place/photo?maxwidth=800&photo_reference=${item.photos[0].photo_reference}&key=${GOOG_API_KEY})`;
        } else {
            return DefaultImg;
        }
    }

    const navigateToFeed = () => {
        navigate('/feed');
    }

    return (
        <>
        {!!userInfo
            && (
            <div className="main">
            <Container className="col-md-6 offset-md-3" >
                <Row>
                    <Col>
                        <h4>Get Inspired!</h4>
                        <Button size='lg' className='btn-primary rounded-pill mb-3' onClick={()=> navigateToFeed()}>Go To Feed</Button>
                    </Col>
                </Row>
            <Form onSubmit={createFolder}>
                <Row className="align-items-center">
                    <Form.Group as={Col} className="pr-0">
                        <Form.Control size="md" className="rounded-pill bg-light border-0 pr-3" type="text" placeholder="Name Your Folder!" value={newFolder} onChange={e => setNewFolder(e.target.value)} id='createTripInput' />
                    </Form.Group>
                    <Form.Group as={Col} xs="auto">
                        <Button size="md" className="rounded-pill btn-primary" type="submit" >
                            Create
                        </Button>
                    </Form.Group>
                </Row>
            </Form>
            {error && (
                <p>Name is invalid.</p>
            )}
            </Container>

            <Row className="mb-3 pb-3 pr-2 pl-0 mr-0 ml-0 w-100 mw-100">
                <Col className='col-md-6 col-xs-12 pr-0 pl-2'>
                    <Accordion>
                    {!!folders && (folders.map((folder, i) => {
                        if (folder.dream_view === true || folder.name === 'Wishlist') {
                            return (
                                <Card key={i} className="mr-0 pr-0 ml-0 mr-0" style={{ overflow: "visible" }}>
                                <Card.Header>
                                    <Row>
                                        <Col className="left-align col-8 pl-2 pr-0">
                                            <Accordion.Toggle as={Button} variant="link" size="md" className="shadow-none text-left p-0" eventKey={i + 1} onClick={() => setCurrentFolder(folder)}>
                                                <h3>{folder.name}</h3>
                                            </Accordion.Toggle>
                                            <UpdateTripName currentTripNameEditable={currentTripNameEditable} setCurrentTripNameEditable={setCurrentTripNameEditable} updateTripName={updateTripName} folder={folder} currentFolder={currentFolder} />
                                        </Col>
                                        <Col className="col-4 d-inline right-align">
                                            {/* <FolderToggle size="md" className="mr-1 p-1" eventKey={i + 1}>
                                                <Image className="m-0 p-0 filter-white" style={{ height: "1.8em",  }} src={FolderIcon} />
                                            </FolderToggle> */}
                                            {folder.name != "Wishlist" && (
                                                <Button size="md" className='btn-transparent mr-1 p-0' 
                                                onClick={() => {
                                                    setCurrentTripNameEditable(true);
                                                    setCurrentFolder(folder);
                                                }}><Pencil size={"1.8em"} color='#424242' /></Button>
                                            )}
                                            {folder.name != "Wishlist" && (
                                                <Button className="ml-1 p-0 btn-transparent" size="md"
                                                    onClick={e => {
                                                        deleteFolder(userInfo.id, folder.id, e);
                                                    }}>
                                                    <Trash size={"1.8em"} color='#424242' />
                                                </Button>
                                            )}
                                        </Col>
                                    </Row>
                                </Card.Header>
                                <Accordion.Collapse className="m-0 pt-2 pb-5 mt-0 pt-0" eventKey={i + 1}>
                                    <Card.Body className='pl-0 pr-0 mt-0 pt-0'>
                                        <FolderView folder={folder} addAble={false} deleteItem={deleteItem} myView={false} changeItemDate={changeItemDate} showImg={false} folders={folders} updateItemNotes={updateItemNotes} followView={false}/>
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                            )
                        }
                    }))}
                    </Accordion>
                </Col>
                {/* <Col className='col-md-6 current-trip-background-img text-center col-xs-12' style={{ backgroundImage: currentFolder.contents ? getCurrentFolderPic(currentFolder.contents) : `url(${DefaultImg})` }}>
                    <h1 className='current-trip-heading mt-5 pt-3'>{!!currentFolder.name ? currentFolder.name : "My Itineraries"}</h1>
                </Col> */}
                <Col className={`col-md-6 mw-100 mobile-hide text-center col-xs-12 ` + (!!currentFolder.contents ? '' : 'current-trip-background-img')} style={{ backgroundImage: currentFolder.contents ? "none" : `url(${DefaultImg})` }}>
                    <h1 className='current-trip-heading position-absolute mt-5 pt-3'>{!!currentFolder.name ? currentFolder.name : "My Inspirations"}</h1>
                    {!!currentFolder.contents && currentFolder.contents.map((place, i) => (
                        <div key={i} className='w-100 mw-100'>
                            <Image className='w-100 mw-100' style={{ height: "auto" }} src={`https://maps.googleapis.com/maps/api/place/photo?maxwidth=800&photo_reference=${place.photos[0].photo_reference}&key=${GOOG_API_KEY}`} alt={place.name} />
                        </div>
                    ))}
                </Col>
            </Row>
            {/* <Container className="mb-3 pb-3">
                {showDetails === true && <FolderView folder={currentFolder} addAble={false} deleteItem={deleteItem} />}
            </Container> */}
        </div>
        )}

        </>
    );
};

export default MyDreams;