import React from 'react';
import { Container } from 'react-bootstrap';

const Rating = ({ width }) => {
    return (
        <div className="rating">
          <div className="rating-fill" style={{ width: width + "%" }}>
            <span className="mr-1">⬤</span>
            <span className="mr-1">⬤</span>
            <span className="mr-1">⬤</span>
            <span className="mr-1">⬤</span>
            <span>⬤</span>
          </div>
          <div className="rating-overlay">
            <span className="mr-1">⬤</span>
            <span className="mr-1">⬤</span>
            <span className="mr-1">⬤</span>
            <span className="mr-1">⬤</span>
            <span>⬤</span>
          </div>
        </div>
    );
}

export default Rating;