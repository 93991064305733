import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Button } from 'react-bootstrap';
import ReactGA from 'react-ga4';

const SignUpButton = () => {
  const { loginWithRedirect, loginWithPopup } = useAuth0();

  const handleLogin = async () => {
    ReactGA.event({
      category: "User Accounts",
      action: "User Signs Up"
    })
    await loginWithRedirect({
      appState: {
        returnTo: "/feed"
      },
    });
    // await loginWithPopup();
  }

  return (
    <Button
      className="btn-secondary rounded-pill"
      size="lg"
      onClick={handleLogin}
    >
      Sign Up
    </Button>
  );
};

export default SignUpButton;