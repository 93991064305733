import React from 'react';
import { Navbar, Nav, Image } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import LogoFont from '../images/logo_font.svg';

const MobileHeader = () => {

    return (
        <>
        <Navbar
            // onSelect={(selectedKey) => alert(`selected ${selectedKey}`)}
            // className="ml-3 mt-3 mr-2 mb-2"
            className="m-0 p-0 bg-white mobile-header"
            sticky="top"
        >
                <Nav style={{ width: "100%", maxWidth: "100%"}}>
                    <Navbar.Brand as={Link} to="/feed" className="pb-2 pt-2 pl-3 mr-0">
                        <Image style={{ height: "1.5em" }} src={LogoFont} alt="Trottr Logo" />
                    </Navbar.Brand>
                </Nav>
        </Navbar>
        </>
    )
}

export default MobileHeader;