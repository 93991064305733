import React from 'react';
import { Container, Row, Col, Image } from 'react-bootstrap';
import LoadingGif from '../images/loading.gif';

const Loading = () => (
    <Container>
        <Row className='mt-5'>
            <Col className='col-md-4 offset-md-4'>
                <Image src={LoadingGif} className='w-100' />
            </Col>
        </Row>
    </Container>
);

export default Loading;