import { useState } from 'react';

const useModal = () => {
    const [isShowing, setIsShowing] = useState(false);
    const [showDetails, setShowDetails] = useState(false);

    const toggle = () => {
        setIsShowing(!isShowing);
    };

    const toggleDetails = () => {
        setShowDetails(!showDetails);
    };

    return {
        isShowing,
        toggle,
        showDetails,
        toggleDetails
    };
};

export default useModal;