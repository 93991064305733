import React, { useState, useEffect} from 'react';
import { MessageCircle } from 'lucide-react';
import { Modal, Row, Col, Form, Button, Image } from 'react-bootstrap';
import { UserState } from '../Recoil/atoms';
import { useRecoilState } from 'recoil';
import { IMG_URL, URL } from '../../config';
import axios from 'axios';
import ReactGA from 'react-ga4';


const Comment = ({ numComments, postId }) => {
    const [comments, setComments] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [comment, setComment] = useState('');
    const [user, setUser] = useRecoilState(UserState);
    const [updatedComment, setUpdatedComment] = useState('');
    const [commentEditable, setCommentEditable] = useState(false);
    const [editCommentIdx, setEditCommentIdx] = useState(null);

    const handleClose = () => setShowModal(false);

    const getComments = async () => {
        ReactGA.event({
            category: "Feed",
            action: "User comments on another users post"
        })
        const url = `${URL}/post/${postId}/comments`;
        const res = await axios.get(url);
        const body = res.data;
        setComments(body);
    }

    const handleSubmitComment = async (e) => {
        e.preventDefault();
        const url = `${URL}/post/${postId}/user/${user.id}/comment`;
        const res = await axios.post(url, { comment });
        setComment('');
        if (res.status === 201) {
            await getComments();
        }
    }

    const handleShow = async () => {
        await getComments();
        setShowModal(true);
    };
    
    const deleteComment = async (commentId) => {
        const url = `${URL}/post/comment/${commentId}`;
        const res = await axios.delete(url);
        getComments();
    };

    const editComment = (index, comment) => {
        setUpdatedComment(comment);
        setCommentEditable(true);
        setEditCommentIdx(index);
    }

    // Utility function to calculate the time difference
const timeSince = (timestamp) => {
    const currentTime = new Date();
    const commentTime = new Date(timestamp);
    const timeDifference = currentTime - commentTime;
  
    // Time constants
    const minutes = Math.floor(timeDifference / (1000 * 60));
    const hours = Math.floor(timeDifference / (1000 * 60 * 60));
    const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
  
    // Return format based on time difference
    if (minutes < 60) {
      return `${minutes}m`;
    } else if (hours < 24) {
      return `${hours}h`;
    } else {
      return `${days}d`;
    }
  };

  const cancelEdit = () => {
    setCommentEditable(false);
    setEditCommentIdx(null);
  };

  const handleSubmitEdit = async (commentId, e) => {
    e.preventDefault();
    setCommentEditable(false);
    setEditCommentIdx(null);
    const url = `${URL}/post/comment/${commentId}`;
    const res = await axios.put(url, { comment: updatedComment });
    if (res.status === 201) {
        getComments();
    }
  }
    
    return (
        <>
            {/* <div className='m-0 p-0 position-relative'> */}
                <Button 
                    className='btn-transparent p-0 mb-0 ml-2 mr-0 mt-1 position-relative'
                    onClick={handleShow}
                >
                    <MessageCircle color='#424242' size={27} style={{ transform: 'scaleX(-1)' }} />
                    {numComments > 0 && (
                        <div className='notification-number-container' style={{ backgroundColor: "white", color: "#424242" }}>
                            <p className='notification-number font-weight-bold'>{numComments}</p>
                        </div>
                    )}
                </Button>
            {/* </div> */}
            {/* <Button 
                className='btn-transparent p-0 mb-0 ml-2 mr-0 mt-1'
                onClick={() => setShowModal(true)}
            >
                <MessageCircle color='#424242' size={30} />
            </Button> */}
            <Modal show={showModal} onHide={handleClose}>
                <Modal.Header closeLabel='text-center' className="bg-light" closeButton>
                    <Modal.Title>Comments</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {comments.length === 0 ? (
                        <Row>
                            <Col className='text-center'>
                                <h6 className='text-secondary mb-3'>There are currently no comments for this post.</h6>
                            </Col>
                        </Row>
                    ) : (
                        <>
                            {comments.map((comment, i) => (
                                <Row key={i}>
                                    <Col className='col-2 pr-0'>
                                        <div className='feed-profile-pic-container mr-0 pr-0'>
                                            {comment.profile_image && <Image src={`${IMG_URL}/${comment.profile_image.path}`} loading='lazy' className='profile-image w-100' />}
                                        </div>
                                    </Col>
                                    <Col className='col-10 pl-0'>
                                        <div>
                                            <h6 className='mb-0 d-inline'>{comment.username}</h6>
                                            <p className='body-text d-inline ml-1'>{timeSince(comment.created_at)}</p>
                                        </div>
                                        {i !== editCommentIdx && (
                                            <p className='text-dark mb-0'>{comment.comment}</p>  
                                        )}
                                        {user.id === comment.user_id && !commentEditable && i !== editCommentIdx && (
                                            <div className='w-100 text-right'>
                                                <p className='geo-text mb-0 mr-4 d-inline' onClick={() => editComment(i, comment.comment)}>Edit</p>
                                                <p className='geo-text mb-0 d-inline' onClick={() => deleteComment(comment.comment_id)}>Delete</p>
                                            </div>
                                        )}
                                        {commentEditable && i === editCommentIdx && (
                                            <Form onSubmit={(e) => handleSubmitEdit(comment.comment_id, e)}>
                                                <Form.Group className='mb-1'>
                                                    <Form.Control as="textarea" rows={2} placeholder='Update your comment' value={updatedComment} onChange={e => setUpdatedComment(e.target.value)} id='updatedCommentInput' />
                                                </Form.Group>
                                                <Form.Group as={Row} className='mt-1 mb-1'>
                                                    <Col className='right-align d-inline'>
                                                        <Button className='btn-primary pr-2 pl-2 pt-1 pb-1 d-inline ml-2' type="submit" size="md">Save</Button>
                                                        <Button className='btn-secondary pr-2 pl-2 pt-1 pb-1 d-inline ml-2' onClick={() => cancelEdit()} size="md">Cancel</Button>
                                                    </Col>
                                                </Form.Group>
                                            </Form>
                                        )}
                                    </Col>
                                </Row>
                            ))}
                        </>
                    )}
                    <Row>
                        <Col className='col-2 pr-0 mt-2'>
                            <div className='feed-profile-pic-container mr-0 pr-0'>
                                {user.profile_image && <Image src={`${IMG_URL}/${user.profile_image.path}`} loading='lazy' className='profile-image w-100' />}
                            </div>
                        </Col>
                        <Col className='col-10 pl-0 mt-2'>
                            <Form onSubmit={handleSubmitComment}>
                                <Form.Group className='mb-1'>
                                    <Form.Control as="textarea" rows={2} placeholder='Add a new comment...' value={comment} onChange={e => setComment(e.target.value)} id='commentInput' />
                                </Form.Group>
                                <Form.Group as={Row} className='mt-1 mb-1'>
                                    <Col className='right-align d-inline'>
                                        {/* <Button className='btn-primary p-1 d-inline' type="submit" size="sm"><Image className='m-0 filter-white' style={{ height: "1.8em" }} src={SaveIcon} /></Button> */}
                                        <Button className='btn-primary pr-2 pl-2 pt-1 pb-1 d-inline ml-2' type="submit" size="md">Save</Button>
                                    </Col>
                                </Form.Group>
                            </Form>
                        </Col>
                    </Row>
                </Modal.Body>
                {/* <Modal.Footer>
                    <Row className='w-100'>
                        <Col>
                            <Form onSubmit={handleSubmitComment}>
                                <Form.Group className='mb-1'>
                                    <Form.Control as="textarea" rows={2} placeholder='Add a new comment...' value={newComment} onChange={e => setNewComment(e.target.value)} id='notesInput' />
                                </Form.Group>
                                <Form.Group as={Row} className='mt-1 mb-1'>
                                    <Col className='right-align d-inline'>
                                        <Button className='btn-primary pr-2 pl-2 pt-1 pb-1 d-inline ml-2' type="submit" size="md">Submit</Button>
                                    </Col>
                                </Form.Group>
                            </Form>
                        </Col>
                    </Row>
                </Modal.Footer>
                 */}
            </Modal>
        </>
    )
};

export default Comment;