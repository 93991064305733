import React from "react";
import ReactDOM from "react-dom";
import 'bootstrap';
import App from "./App.js";
import { RecoilRoot } from 'recoil';
import './Styles/index.scss';
import { CookiesProvider } from 'react-cookie';
import { BrowserRouter as Router } from 'react-router-dom';
import Auth0ProviderWithNavigate from './components/Auth0Login/Auth0ProviderWithNavigate.js';
import ReactGA from 'react-ga4';
import { createRoot } from 'react-dom/client';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

ReactGA.initialize('G-4JV2F9JGEE');



const root = createRoot(document.getElementById('app'));
root.render(
    <Router>
      <Auth0ProviderWithNavigate>
          <RecoilRoot>
            <CookiesProvider>
                <App />
            </CookiesProvider>
          </RecoilRoot>
      </Auth0ProviderWithNavigate>
    </Router>
);

// serviceWorkerRegistration.register();

