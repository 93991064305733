import React, { useState } from 'react';
import UserView from './UserView.js';
import axios from 'axios';
import { URL } from '../../config.js';
import { Form, Row, Col, Button, Container, Image } from 'react-bootstrap';
import { Input } from 'react-rainbow-components';
import ReactGA from 'react-ga4';
import { useRecoilState } from 'recoil';
import { followedUserState } from '../Recoil/atoms.js';
import { useNavigate } from 'react-router-dom';
import { Search } from 'lucide-react';

const Follow = () => {
    const [input, setInput] = useState('');
    const [userView, setUserView] = useState(false);
    const [users, setUsers] = useState([]);
    const [currentUser, setCurrentUser] = useState();
    const [searchError, setSearchError] = useState(false);
    const [followedUser, setFollowedUser] = useRecoilState(followedUserState);
    const navigate = useNavigate();

    const handleSubmit = (e) => {
        setSearchError(false);
        setUsers([]);
        e.preventDefault();
        ReactGA.event({
            category: "Follow",
            action: "User Searches Another User"
        })
        const user = input;
        axios.get(`${URL}/users/search/${user}`)
        .then(({data}) => {
            // console.log(data);
            setUserView(false);
            setInput('');
            // console.log('User data: ', data);
            if (data.length > 0) {
                setUsers(data);
            } else {
                setSearchError(true);
            }
        })
    };

    const viewUserFolders = (user) => {
        ReactGA.event({
            category: "Follow",
            action: "User Views Another Users Trips"
        })
        setUserView(true);
        setCurrentUser(user);
        setFollowedUser(user);
        navigate(`/${user.username}`);
    }
    return (
        <div className="m-0 p-0">
        <Container className="mb-2">
            <Col className='col-md-8 offset-md-2'>
            <Form onSubmit={handleSubmit}>
                {/* <Form.Group className="col-md-4 offset-md-4" controlId="formBasicEmail">
                    <Form.Row>
                        <Col>
                            <Form.Control size="md" className="rounded-pill bg-light border-0" name="username" placeholder="Search Accounts" value={input} onChange={e => setInput(e.target.value)} />
                        </Col>
                        <Col xs="auto">
                            <Button size="md" className="rounded-pill btn-primary" variant="primary" type="submit">
                            Search
                            </Button>
                        </Col>
                </Form.Row>
                </Form.Group> */}
                <Input
                    className='mb-2 rainbow-input'
                    placeholder='Search by username or first and last name'
                    icon={<Search color='gray' />}
                    value={input}
                    onChange={e => setInput(e.target.value)}
                />
                <Button size="md" className="rounded-pill btn-primary" variant="primary" type="submit">
                    Search
                </Button>
            </Form>
            </Col>
            </Container>
            {userView === false && !!users.length && (
                <Container className="col-md-6 offset-md-3 shadow bg-white rounded p-3">
                    <h5 className="left-align mb-2">People</h5>
                    {users.map((user, i) => (
                    <Row key={i} className="border-bottom pb-0 mt-2 border-black-50">
                        <Col className="left-align mb-0 pb-0">
                            <h6 className="mb-0 pb-0">@{user.username}</h6>
                            <p className="geo-text mb-0 pb-1 pl-2">{user.first_name + ' ' + user.last_name}</p>
                        </Col>
                        <Col className="right-align">
                            <Button size="sm" variant="secondary" onClick={e => viewUserFolders(user)}>View Trips</Button>
                        </Col>
                    </Row>
                ))}
                </Container>
            )}
            {searchError && (
                <Container className="col-md-6 offset-md-3 p-3 text-center">
                    <h6>Sorry, we could not find any users with that username.</h6>
                    <h6>Please try searching another user.</h6>
                </Container>
            )}
            {userView === true && <UserView user={currentUser} />}
        </div>
    );
};

export default Follow;