import React from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import MyTrips from './MyTrips';
import MyBlogs from '../Blogs/MyBlogs';
import { useNavigate, useLocation } from 'react-router-dom';
import Profile from '../Profile/Profile';
import { useRecoilState } from 'recoil';
import { UserState } from '../Recoil/atoms';
import { URL } from '../../config';
import axios from 'axios';
import MyDreams from './MyDreams';


const Trips = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [user, setUser] = useRecoilState(UserState);


    const formatViewHeading = (targetLocation) => {
        if (targetLocation === location.pathname) {
            return 'solid black';
        } else {
            return "none";
        }
    }

    const uploadMainBackgroud = async (formData) => {
        console.log('updating trip background pic...');
        console.log('user: ', user);
        const res = await axios.post(`${URL}/users/${user.id}/profile/trips/defaultimage`, formData, { headers: {'Content-Type': 'multipart/form-data'}});
        return res;
    }

    const updateTripName = async (folderId, folderName) => {
        const body = {
            folderName: folderName
        }

        const url = `${URL}/users/folders/${folderId}/name`;
        const res = await axios.put(url, body);
        console.log('updated trip name: ', res);
        return res;
    }


    return (
        <>
        {!!user && (
            <div className='main'>
                <Profile user={user} setUser={setUser} followView={false} />
                <Row className='mb-2 mt-4 mw-100 pl-2 mr-0 ml-0'>
                    <Col className='col-md-4'>
                        <h6 className='cursor-pointer' style={{ borderBottom: formatViewHeading('/profile/dream') }} onClick={() => navigate('/profile/dream')}>Dream</h6>
                    </Col>
                    <Col className='col-md-4'>
                        <h6 className='cursor-pointer' style={{ borderBottom: formatViewHeading('/profile/plan') }} onClick={() => navigate('/profile/plan')}>Plan</h6>
                    </Col>
                    <Col className='col-md-4'>
                        <h6 className='cursor-pointer' style={{ borderBottom: formatViewHeading('/profile/share') }} onClick={() => navigate('/profile/share')}>Share</h6>
                    </Col>
                </Row>
                    {/* {!!planView ? <MyTrips /> : <MyBlogs />} */}
                    {location.pathname === '/profile/share' && <MyBlogs uploadMainBackground={uploadMainBackgroud} updateTripName={updateTripName} />}
                    {location.pathname === '/profile/dream' && <MyDreams updateTripName={updateTripName} />}
                <Container className='col-12'>
                    {location.pathname === '/profile/plan' && <MyTrips uploadMainBackground={uploadMainBackgroud} updateTripName={updateTripName} />}
                </Container>
            </div>  
        )}
        </>
    )
}

export default Trips;