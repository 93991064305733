import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import { URL } from '../../config.js';
import Place from '../Experiences/Place.js';
import { folderState, UserState } from '../Recoil/atoms.js';
import { useRecoilValue, useRecoilState } from 'recoil';
import { Form, Button, Col, Container, Image, Row } from 'react-bootstrap';
import { useCookies } from 'react-cookie';
import { Input } from 'react-rainbow-components';
import ReactGA from 'react-ga4';
import { Search } from 'lucide-react';

const Discover = ({ postView, setPostPlace, tripView, folder, blogView }) => {
    const [results, setResults] = useState([]);
    const folders = useRecoilValue(folderState);
    // const [addAble, setAddAble] = useRecoilState(addAbleState);
    const { register, handleSubmit, watch, reset, errors } = useForm();
    const [cookies, setCookies, removeCookie] = useCookies(['City', 'Country', 'user', 'searchTerm']);
    const [searchError, setSearchError] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');
    const [searchTerm, setSearchTerm] = useState('');
    const [searchResults, setSearchResults] = useState([]);
    const [nextPageToken, setNextPageToken] = useState('');
    const [userInfo, setUserInfo] = useRecoilState(UserState);
    const [searchCategory, setSearchCategory] = useState('');
    const primary = "#15084D";
    const secondary = "#06439e";

    // useEffect(() => {
    //     setSearchError(false);
    // }, [])
    // console.log('search category: ', searchCategory === 'things to do in ');


    const onSubmit = data => {
        let params = {
            params: data
        };
        setCookies('City', data.City, { path: '/' });
        setCookies('Country', data.Country, { path: '/' });
        console.log('Search Term: ', data.City + ' ' + data.Country);
        setSearchQuery(data.City + ' ' + data.Country)
        console.log('search cookies: ', cookies);
        reset({});
    };

    const searchPlaces = e => {
        e.preventDefault();
        // console.log('searching: ', searchTerm)
        setCookies('searchTerm', searchTerm);
        const params = {
            params: {
                query: searchCategory + searchTerm
            }
        };
        setSearchTerm('');
        setSearchCategory('');
        axios.get(`${URL}/places/search/textquery`, params)
        .then(({data}) => {
            // if (!data.length < 1) {
            //     setSearchError(true);
            // }
            // console.log('search data: ', data);
            setNextPageToken(data.next_page_token);
            // console.log('search data: ', data);
            let results = data.results.filter(result => !!result.photos);
            if (results.length < 1) {
                setSearchError(true);
            } else {
                setSearchError(false);
            }
            setSearchResults(results);
            // console.log('results: ', searchResults);
            ReactGA.event({
                category: "Search",
                action: "User Searched Places"
            })
            
        })
        .catch(err => {
            console.log(err);
            setSearchError(true);
        })
    }


    const loadMoreResults = e => {
        e.preventDefault();
        // console.log('next page token: ', nextPageToken);
        const params = {
            params: {
                page_token: nextPageToken
            }
        }

        axios.get(`${URL}/places/search/nextpage`, params)
        .then(({data}) => {

            setNextPageToken(data.next_page_token);
            
            let results = data.results.filter(result => !!result.photos);
            if (results.length < 1) {
                setSearchError(true);
            }
            let newResults = searchResults.concat(results);
            setSearchResults(newResults);
            // console.log('results: ', searchResults);
            
        })
    };

    const handleCategoryChange = (category) => {
        if (searchCategory !== category) {
            // console.log(category);
            setSearchCategory(category)
        } else {
            setSearchCategory('');
        }
    }


    return (
        <>
        {!!userInfo && (
            <div className="m-0 p-0">
            <Container className='mb-2'>
                <Col className={!!postView ? 'col-md-12' : 'col-md-8 offset-md-2'}>
                <Form onSubmit={searchPlaces}>
                    <Row>
                            <Input
                                className='mb-1 rainbow-input w-100'
                                placeholder='Search for a place or geography'
                                icon={<Search color='gray' />}
                                value={searchTerm}
                                onChange={e => setSearchTerm(e.target.value)}
                            />
                    </Row>
                    {!postView && !blogView && (
                        <>
                        <Row>
                            <p className='geo-text mt-0 mb-0'>Categories (optional)</p>
                        </Row>
                        <Row className='d-inline'>
                            <Button variant="secondary" size="sm" className='category-btn d-inline rounded-pill mr-1 mt-1' onClick={() => handleCategoryChange('things to do in ')} style={{ backgroundColor: searchCategory === 'things to do in ' ? secondary : "white", color: searchCategory === 'things to do in ' ? "white" : secondary }}>things to do</Button>
                            <Button variant="secondary" size="sm" className='category-btn d-inline rounded-pill mr-1 ml-1 mt-1' onClick={() => handleCategoryChange('hotels in ')} style={{ backgroundColor: searchCategory === 'hotels in ' ? secondary : "white", color: searchCategory === 'hotels in ' ? "white" : secondary }}>hotels</Button>
                            <Button variant="secondary" size="sm" className='category-btn d-inline rounded-pill mr-1 ml-1 mt-1' onClick={() => handleCategoryChange('restaurants in ')} style={{ backgroundColor: searchCategory === 'restaurants in ' ? secondary : "white", color: searchCategory === 'restaurants in ' ? "white" : secondary }}>restaurants</Button>
                            <Button variant="secondary" size="sm" className='category-btn d-inline rounded-pill mr-1 ml-1 mt-1' onClick={() => handleCategoryChange('museums in ')} style={{ backgroundColor: searchCategory === 'museums in ' ? secondary : "white", color: searchCategory === 'museums in ' ? "white" : secondary }}>museums</Button>
                            <Button variant="secondary" size="sm" className='category-btn d-inline rounded-pill mr-1 ml-1 mt-1' onClick={() => handleCategoryChange('shopping in ')} style={{ backgroundColor: searchCategory === 'shopping in ' ? secondary : "white", color: searchCategory === 'shopping in ' ? "white" : secondary }}>shopping</Button>
                            <Button variant="secondary" size="sm" className='category-btn d-inline rounded-pill mr-1 ml-1 mt-1' onClick={() => handleCategoryChange('sacred and religous sites in ')} style={{ backgroundColor: searchCategory === 'sacred and religous sites in ' ? secondary : "white", color: searchCategory === 'sacred and religous sites in ' ? "white" : secondary }}>religious sites</Button>
                            <Button variant="secondary" size="sm" className='category-btn d-inline rounded-pill mr-1 ml-1 mt-1' onClick={() => handleCategoryChange('hiking and outdoors in ')} style={{ backgroundColor: searchCategory === 'hiking and outdoors in ' ? secondary : "white", color: searchCategory === 'hiking and outdoors in ' ? "white" : secondary }}>hiking & outdoors</Button>
                        </Row>
                        </>
                    )}
                    <Row>
                        <Col className='text-center mt-1'>
                            <Button size="md" className="m-0 rounded-pill btn-primary" variant="primary" type="submit" onSubmit={searchPlaces}>
                                    Search
                            </Button>
                        </Col>
                    </Row>
                </Form>
                </Col>
            </Container>
            <Container className="mb-3 pb-3 p-0">
            {/* {results && !searchError && results.map((item, i) => (
                <Container key={i}>
                    <Item item={item} addAble={true} folders={folders} />
                </Container>
            ))} */}
            {!!searchError && (
                <div>
                    <p className="mt-4 mb-0">Sorry, your search returned no results.</p>
                    <p className="mt-1">Please check spelling and use full names.</p>
                </div>
            )}
            {!!searchResults && searchResults.map((place, i) => (
                <Container className='p-0' key={i}>
                    <Place item={place} addAble={true} folders={folders} showImg={true} searchView={true} setPostPlace={setPostPlace} postView={postView} tripView={tripView} folder={folder} blogView={false} />
                </Container>
                
            ))}
            {searchResults.length > 0 && nextPageToken && (
                <Container className='mb-5 mt-2'>
                    <Button className='rounded-pill' style={{ backgroundColor: "dimgray", borderColor: "dimgray" }} onClick={loadMoreResults}>Load More...</Button>
                </Container>
            )}
            </Container>
        </div>
        )}
        </>
    )
};

export default Discover;