import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import { Input } from 'react-rainbow-components';
import { URL } from '../../config';
import axios from 'axios';
import { Search } from 'lucide-react';
import BlogCard from '../Blogs/BlogCard';
import { useCookies } from 'react-cookie';
import { useRecoilState } from 'recoil';
import { UserState } from '../Recoil/atoms';


const Feed = () => {
    const [searchTerm, setSearchTerm] = useState('');
    const [blogs, setBlogs] = useState([]);
    const [cookies, setCookies, removeCookie] = useCookies(['blogTerm']);
    const [noResults, setNoResults] = useState(false);
    const [showFeaturedBlogs, setShowFeaturedBlogs] = useState(true);
    const [showMore, setShowMore] = useState(true);
    const [searchMode, setSearchMode] = useState(false);
    const [savedSearchTerm, setSavedSearchTerm] = useState('');
    const [user, setUser] = useRecoilState(UserState);

    const searchBlogs = async (e) => {
        setBlogs([]);
        setNoResults(false);
        setSearchMode(true);
        setShowMore(true);
        setShowFeaturedBlogs(false);
        e.preventDefault();

        const expirationDate = new Date();
        expirationDate.setDate(expirationDate.getDate() + 1);
        setCookies('blogTerm', searchTerm, { path: '/', expires: expirationDate });

        const url = `${URL}/blogs/search/location/count/8/offset/0`;
        const res = await axios.get(url, { params: { location: searchTerm }});
        setSavedSearchTerm(searchTerm)
        setSearchTerm('');
        if (res.data.length === 0) {
            setNoResults(true);
        }

        setBlogs(res.data);
    };

    const getBlogsFromCookie = async () => {
        const url = `${URL}/blogs/search/location`;
        const res = await axios.get(url, { params: { location: cookies.blogTerm }});
        console.log('res data: ', res);
        setBlogs(res.data);
    };

    const getFeaturedBlogs = async () => {
        const url = `${URL}/feed/count/8/offset/0`;
        const res = await axios.get(url);
        setBlogs(res.data);
    };

    useEffect(() => {
        // if (!!cookies.blogTerm) {
        //     getBlogsFromCookie();
        // } else {
        //     setShowFeaturedBlogs(true);
        //     getRecentBlogs();
        // }
        getFeaturedBlogs();
    },[]);

    const getMorePosts = async (e) => {
        e.preventDefault();
        let url;
        let res;
        if (!!searchMode) {
            url = `${URL}/blogs/search/location/count/8/offset/${blogs.length}`;
            res = await axios.get(url, { params: { location: savedSearchTerm }});
        } else {
            url = `${URL}/feed/count/8/offset/${blogs.length}`;
            res = await axios.get(url);
        }
        const newBlogs = res.data;
        const updatedFeedData = [...blogs, ...newBlogs];
        const oldFeedCount = blogs.length;
        const newFeedCount = updatedFeedData.length;
        if (oldFeedCount === newFeedCount) {
            setShowMore(false);
        } else {
            setBlogs(updatedFeedData);
        }
    };

    
    return (
        <div className="m-0 p-0">
        {!!user && (
            <Container className='mb-2 mt-1'>
            <Row>
                <Col className='col-md-8 offset-md-2'>
                    <Form onSubmit={searchBlogs}>
                        <Row>
                            <Col>
                                <Input
                                    className='mb-1 rainbow-input w-100'
                                    placeholder='Search a destination...'
                                    icon={<Search color='gray' />}
                                    value={searchTerm}
                                    onChange={e => setSearchTerm(e.target.value)}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col className='text-center mt-1'>
                                <Button size="md" className="m-0 rounded-pill btn-primary" variant="primary" type="submit" onSubmit={searchBlogs}>
                                        Search
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Row>
                        <Col>
                            {!!showFeaturedBlogs ? <h4 className='mt-3 mb-0'>Featured Trip Guides</h4> : <h4 className='mt-3 mb-0'>Search Results</h4>}
                            {/* {!!noResults && <h6 className='text-secondary mt-3'>Your search did not return any results. Try searching a different location.</h6>} */}
                        </Col>
                    </Row>
                    <Row className="mb-3">
                            {!!blogs && blogs.map((folder, i) => {
                                return (
                                    <Col className='col-md-6 col-12 pr-1 pl-1' key={i}>
                                        <BlogCard feedView={true} folder={folder} />
                                    </Col>
                                )
                            })}
                    </Row>
                </Col>
            </Row>
            <Row className='mt-3 mb-3'>
                <Col>
                    {!!showMore && !noResults ? (
                        <Button onClick={getMorePosts} className='btn-primary rounded-pill'>More Posts...</Button>
                    ) : !!noResults ? (
                        <h6 className='text-secondary mt-3'>Your search did not return any results. Try searching a different location.</h6>
                    ) : (
                        <h4 className='text-secondary'>You're all caught up!</h4>
                    )}
                </Col>
            </Row>
        </Container>
        )}
        </div>
    );
};

export default Feed;